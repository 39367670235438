import { useNetwork } from 'ahooks';
import { useEffect } from 'react';
import { message } from 'antd';

export default function useCheckNetwork() {
  const network = useNetwork();
  useEffect(() => {
    if (!network.online) {
      message.error('无网络链接');
    }
  }, [network.online]);
}

export { useCheckNetwork };
