/**
 * tezign ownership
 * @owner
 * @team
 */
import { InfoCircleOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import { Button } from 'antd';
import classNames from 'classnames';
import { defineStore } from 'shared/hooks/defineStore';
import $style from './index.module.less';
import { appIdToAppNameInUI } from '@/config/apps-config';
import Modal from '@/components/Modal/Modal';
import i18n from '@/locales/i18n';
import useCountdownLoading from '@/hooks/useCountdownLoading';

export interface IAppModalTipBindProps {
  visible: boolean;
  errorCb?: () => void;
  okCb?: () => void;
  conflictData?: any;
  formattedPhone?: string;
}

const { hook: useModalState, updateStore: updateModalState } = defineStore(
  { visible: false } as IAppModalTipBindProps,
  {
    name: 'BizPhoneForceModal'
  }
);
export const showAppModalTipBind = (props: Partial<IAppModalTipBindProps>) => {
  updateModalState((state) => ({
    ...state,
    ...props,
    visible: true
  }));
};
export const closeAppModalTipBind = () => {
  updateModalState((state) => ({
    ...state,
    visible: false
  }));
};
const AppModalTipBind = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { count, loading, setcount } = useCountdownLoading({ countNum: 0 });
  const { visible, errorCb, okCb, conflictData, formattedPhone } = useModalState();
  const allAppName = Object.keys(appIdToAppNameInUI).reduce((target: any, appId) => {
    target[appId] = { name: appIdToAppNameInUI[appId as EAppIds], isUsed: false };
    return target;
  }, {});
  if (conflictData) {
    conflictData?.conflictInfo?.appAuthorizationInfo?.forEach((ele: any) => {
      if (allAppName[ele.appId]) {
        allAppName[ele.appId]['isUsed'] = true;
      }
    });
  }
  useEffect(() => {
    visible && setcount(10);
  }, [visible]);

  return (
    <Modal
      data-test="forceBindModal-modal2"
      visible={visible}
      title={
        <div className="flex align-center">
          <InfoCircleOutlined className="color-error text-[22px]" />
          <span className="ml-[16px]">{i18n.t('数据清除风险！')}</span>
        </div>
      }
      bodyStyle={{ padding: '24px 24px 0' }}
      showCloseIcon
      centered
      okText={i18n.t('确认')}
      cancelText={i18n.t('取消')}
      onClose={() => {
        closeAppModalTipBind();
        errorCb?.();
      }}
      onCancel={() => {
        closeAppModalTipBind();
        errorCb?.();
      }}
      className={$style.BizPhoneforceBindModall}
      footer={
        <div className="flex align-center justify-end">
          <Button
            onClick={() => {
              closeAppModalTipBind();
              errorCb?.();
            }}
            className="mr-[8px] color-basic-6"
          >
            暂不绑定
          </Button>
          <Button
            data-test="forceBindModal2Btn-confirm"
            loading={loading}
            type={loading ? 'ghost' : 'default'}
            disabled={loading}
            onClick={() => {
              closeAppModalTipBind();
              okCb?.();
            }}
          >
            {loading && `${count}秒后可操作`}
            {!loading && '我已确定并绑定'}
          </Button>
        </div>
      }
    >
      <div className={classNames('ml-[36px]', $style.modal_content)}>
        <p>
          手机账号 <strong>{formattedPhone}</strong> 已注册并有使用数据。
        </p>
        <p>
          若继续绑定，<span className="color-error">该账号下所有历史数据将被全部清除！</span>
          请确保该账号下的历史数据已备份或不再使用。
        </p>
        <div className="flex flex-col ">
          <span>使用情況如下：</span>
          <ul className="pl-[20px]">
            {Object.values(allAppName)
              .sort((x, y) => (!x?.isUsed ? 1 : -1))
              .map((ele: { isUsed: boolean; name: string }) => {
                return ele?.isUsed ? (
                  <li className="color-error"> {ele.name} 已授权｜有数据清空风险 </li>
                ) : (
                  <li> {ele.name} 暂未使用</li>
                );
              })}
          </ul>
        </div>
      </div>
    </Modal>
  );
};

export default AppModalTipBind;
