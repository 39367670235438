/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import { defineStore as sharedDefineStore, IGetters } from 'shared/hooks/defineStore';

export const defineStore = <T, Getters extends object>(
  initialState: T,
  options: {
    name: string;
    getters?: IGetters<T, Getters>;
  }
) =>
  sharedDefineStore(initialState, {
    ...options,
    dev: __DEV__
  });

export default defineStore;
