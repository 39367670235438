/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */

// 检测当前环境是否为微信浏览器
export function isWechatBrowser() {
  return /MicroMessenger/i.test(window.navigator.userAgent);
}
