/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import type { AxiosInstance } from 'axios';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { History } from 'history';
import { flushUserCookies, getTokens } from 'shared/utils/cookie';
import Cookies from 'js-cookie';
import { ErrorAppResponse, ErrorNotLoggedIn, ErrorServerSide, Response_Errors } from '@/services/errors';
import isAccessibleRoute from '@/routes/routesPermission';
import { goToLoginPage } from '@/utils/navagation';

nProgress.configure({
  minimum: 0.1,
  showSpinner: false
});

declare module 'axios' {
  export interface AxiosRequestConfig {
    // 其他的设置，用于在拦截器通信
    meta?: {
      // 是否不使用progress
      noProgress?: boolean;
    };
  }
}

const requestTzUserCenter = axios.create({
  baseURL: `${window.$config['MUSEDAM_USERCENTER_URL']}/user-center`
});
const museDamAxios = axios.create({
  baseURL: `${window.$config['MUSEDAM_SERVER_URL']}`
});
const museAiAxios = axios.create({
  baseURL: `${window.$config['MUSEDAM_AUTH2_URL']}`
});

const museDamProfessionalAxios = axios.create({
  baseURL: `${window.$config['MUSEDAM_SERVER_URL']}`
});

const whiteListCode = new Set([0, 200]);

const errHandler = (error: any) => {
  if (nProgress.isStarted()) {
    nProgress.done();
  }

  // eslint-disable-next-line eqeqeq
  if (error.status == 500) {
    return Promise.reject(new ErrorServerSide(error?.message || error));
  }
  return Promise.reject(error);
};

export function useInitAxios() {
  const history = useHistory<any>();
  useEffect(() => {
    const eject = setupInterceptors(requestTzUserCenter, history);
    const museDamEject = setupInterceptors(museDamAxios, history);
    const museDamProfessionalAxiosEject = setupInterceptors(museDamProfessionalAxios, history, false, true);
    const museAIEject = setupInterceptors(museAiAxios, history, false);

    return () => {
      eject();
      museDamEject();
      museDamProfessionalAxiosEject();
      museAIEject();
    };
  }, [history]);
}

const responseInterceptor =
  (options: { history: History; flushUserCookies: () => void; isAccessibleRoute: () => boolean }) =>
  (v: AxiosResponse) => {
    const { history, flushUserCookies, isAccessibleRoute } = options;

    if (nProgress.status) {
      nProgress.done();
    }

    const code = parseInt(v.data.code, 10);

    if (code === 50001) {
      // 登录过期或未登录或无效token
      flushUserCookies();

      if (!isAccessibleRoute()) {
        goToLoginPage(history);
      }

      return Promise.reject(new ErrorNotLoggedIn(code, v.data.message));
    }

    if (!whiteListCode.has(code)) {
      return Promise.reject(new (Response_Errors[code] || ErrorAppResponse)(v.data.message, v.data.result));
    }

    v.data = v.data.result;
    return v;
  };

const responseAiInterceptor =
  (options: { history: History; flushUserCookies: () => void; isAccessibleRoute: () => boolean }) =>
  (v: AxiosResponse) => {
    const { history, flushUserCookies, isAccessibleRoute } = options;

    if (nProgress.status) {
      nProgress.done();
    }

    const code = parseInt(v.data.code, 10) || 200;

    if (code === 50001) {
      // 登录过期或未登录或无效token
      flushUserCookies();

      if (!isAccessibleRoute()) {
        goToLoginPage(history);
      }

      return Promise.reject(new ErrorNotLoggedIn(code, v.data.message));
    }

    if (!whiteListCode.has(code)) {
      return Promise.reject(new (Response_Errors[code] || ErrorAppResponse)(v.data.message, v.data.result));
    }

    v.data = v.data;
    return v;
  };

type TGetTokens = typeof getTokens;
const requestInterceptor = (options: { getTokens: TGetTokens; isNeedHeader?: boolean }) => (v: AxiosRequestConfig) => {
  const { getTokens, isNeedHeader = true } = options;
  const tokens = getTokens();
  console.log('@@@ [requestInterceptor].tokens', tokens);
  if (tokens && isNeedHeader) {
    v.headers['x-global-user-id'] = tokens.userId;
    v.headers['x-sso-token'] = tokens.token;
    v.headers['x-user-pool-id'] = tokens.userPoolId;
  }
  if (!nProgress.isStarted() && !v.meta?.noProgress) {
    nProgress.start();
  }
  return v;
};

const requestInterceptorForMuseDAM = (options: { getTokens: TGetTokens }) => (v: AxiosRequestConfig) => {
  const { getTokens } = options;
  const tokens = getTokens();
  if (tokens) {
    v.headers['x-user-id'] = tokens.userId;
    v.headers['x-token'] = Cookies.get('xtoken');
  }
  if (!nProgress.isStarted() && !v.meta?.noProgress) {
    nProgress.start();
  }
  return v;
};

function setupInterceptors(request: AxiosInstance, history: History, isNeedCode = true, isMuseDAM = false) {
  const requestFn = isMuseDAM ? requestInterceptorForMuseDAM : requestInterceptor;
  const responseFn = isNeedCode ? responseInterceptor : responseAiInterceptor;
  const id1 = request.interceptors.request.use(requestFn({ getTokens, isNeedHeader: isNeedCode }), errHandler);
  const id2 = request.interceptors.response.use(
    responseFn({ history, flushUserCookies, isAccessibleRoute }),
    errHandler
  );
  return () => {
    request.interceptors.request.eject(id1);
    request.interceptors.response.eject(id2);
  };
}

export const __exportedForTesting = {
  requestInterceptor,
  responseInterceptor
};

export default requestTzUserCenter;
export { museDamAxios, museDamProfessionalAxios, museAiAxios };
