/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
/* eslint-disable no-template-curly-in-string */
import { ConfigProviderProps } from 'antd/lib/config-provider';
import { ConfigProvider } from 'antd';
import { IThemeVars, updateCssVars } from './theme-config';

const typeTemplate = "'${name}' 不是有效的${type}类型";

export function setTheme(themeVars: Partial<IThemeVars>) {
  ConfigProvider.config({
    theme: themeVars
  });
  updateCssVars(themeVars);
  // document.body.setAttribute('theme', theme);
}

const antdConfig: ConfigProviderProps = {
  form: {
    validateMessages: {
      default: "校验不通过 '${name}'",
      required: "'${name}' 是必选字段",
      enum: "'${name}' 必须在[${enum}]中选择一个",
      whitespace: "'${name}' 不能有空格",
      date: {
        format: "'${name}' is invalid for format date",
        parse: "'${name}' could not be parsed as date",
        invalid: "'${name}' is invalid date"
      },
      types: {
        string: typeTemplate,
        method: typeTemplate,
        array: typeTemplate,
        object: typeTemplate,
        number: typeTemplate,
        date: typeTemplate,
        boolean: typeTemplate,
        integer: typeTemplate,
        float: typeTemplate,
        regexp: typeTemplate,
        email: typeTemplate,
        url: typeTemplate,
        hex: typeTemplate
      },
      string: {
        len: "'${name}' must be exactly ${len} characters",
        min: "'${name}' must be at least ${min} characters",
        max: "'${name}' cannot be longer than ${max} characters",
        range: "'${name}' must be between ${min} and ${max} characters"
      },
      number: {
        len: "'${name}' must equal ${len}",
        min: "'${name}' cannot be less than ${min}",
        max: "'${name}' cannot be greater than ${max}",
        range: "'${name}' must be between ${min} and ${max}"
      },
      array: {
        len: "'${name}' must be exactly ${len} in length",
        min: "'${name}' cannot be less than ${min} in length",
        max: "'${name}' cannot be greater than ${max} in length",
        range: "'${name}' must be between ${min} and ${max} in length"
      },
      pattern: {
        mismatch: "'${name}' does not match pattern ${pattern}"
      }
    }
  }
};

export default antdConfig;
