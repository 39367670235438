/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import React, { MouseEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans } from 'react-i18next';
import { Button, Divider, message, TabPaneProps, Tabs, Tooltip } from 'antd';
import { EMuseApps } from 'shared/types';
import tracer from 'shared/tracer/tracer';
import classNames from 'classnames';
import { useAsyncEffect } from 'ahooks';
import { trackVisitorRegisterStepLoginSuccess } from 'shared/tracer/register-analysis';
import { flushUserCookies, setSSOCookies } from 'shared/utils/cookie';
import { useHistory, useParams } from 'react-router-dom';
import AppThirdSSO from '../AppThirdSSO/AppThirdSSO';
import $style from './AppAuth.module.less';
import AppAuthLoginCarousel from './AppAuthLoginCarousel';
import { LoginFormPhoneOrEmailWithCode } from './LoginFormPhoneOrEmailWithCode';
import { LoginFormPhoneOrEmailWithPassword } from './LoginFormPhoneOrEmailWithPassword';
import LogoBigMuseDam from '@/assets/logo-big-muse-dam.svg';
import LogoBigMuseTransfer from '@/assets/logo-big-muse-transfer.svg';
import { changeShakeRadio, toggleIsCheck, useStore } from '@/store/app-store';
import { ELoginMethods, IFooterLogo } from '@/types';

import { FormWxServiceAccountQrCode } from '@/components-view/AppAuth/LoginFormWxServiceAccountQrCode';
import { FormWxQrCode } from '@/components-view/AppAuth/LoginFormWxQrCode';

import BizPhoneForceBindModal from '@/components-business/BizPhoneForceModal/BizPhoneForceModal';
import appsConfig from '@/config/apps-config';

import logoMuseTransfer from '@/assets/museTransfer.svg';
import logoMuseLink from '@/assets/museLink.svg';
import logoMuseArt from '@/assets/museArt.svg';
import { MUSE_ART_URL, MUSE_LINK_URL, MUSE_TRANSFER_URL } from '@/utils/constants';
import {
  getSSOResult,
  serviceGetSSOList,
  serviceGetSSOTokenByWxSSO,
  serviceGetThirdSSOToken,
  serviceLoginBySSOList
} from '@/services/authentication';
import BizSsoListLogin from '@/components-business/BizSsoListLogin/BizSsoListLogin';
import BizContactUs from '@/components-business/BizContactUs/BizContactUs';
import {
  changeSSOTagname,
  closeAppModal,
  refreshModel,
  showAppModal,
  useModalState
} from '@/components-business/BizSSOScan/BizSSOScan';
import { getRedirectUrlFromUrl } from '@/utils/login';
import { addParam } from '@/utils/url';
import handleRedirectToApp, { LOGIN_JUMP_TYPE } from '@/utils/ssoRedirect';
import { ContainerTypeEnum, getContainerType, getPlatformType, PlatformTypeEnum } from '@/utils/getContainerType';

export enum ELoginType {
  default = 'default',
  dingding = 'dingtalk',
  feishu = 'feishu',
  qiwei = 'qiyewechat'
}
const logos = {
  [EMuseApps.museDam]: LogoBigMuseDam,
  [EMuseApps.museTransfer]: LogoBigMuseTransfer
};

export default function AppAuthLogin() {
  const { appName, appConfig, isCheck } = useStore();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { inputName } = useModalState();
  const [currentLoginType, setCurrentLoginType] = useState<ELoginType>(ELoginType.default);
  const { loginType, ssoTag } = useParams<{ loginType: ELoginType; ssoTag?: string }>();

  const [activeTabKey, setActiveTabKey] = useState<string | undefined>(undefined);

  useEffect(() => {
    const init = () => {
      if (loginType) {
        toggleIsCheck();
        setCurrentLoginType(
          (Object.values(ELoginType).includes(loginType) ? loginType : ELoginType.default) as ELoginType
        );
      }
      if (ssoTag) {
        changeSSOTagname(ssoTag);
      }
      if (loginType && ssoTag) {
        handelOkCb(ssoTag, loginType);
      }
    };
    init();
  }, []);

  const tabs: (TabPaneProps & {
    id: ELoginMethods;
    renderTab?: (style: React.CSSProperties) => React.ReactNode;
    Component: React.FC;
  })[] = useMemo(
    () => [
      {
        id: ELoginMethods.phoneOrEmailWithCode,
        tab: <Trans>验证码登录</Trans>,
        Component: memo(() => <LoginFormPhoneOrEmailWithCode />)
      },
      {
        id: ELoginMethods.password,
        tab: <Trans>密码登录</Trans>,
        Component: memo(() => <LoginFormPhoneOrEmailWithPassword onSwitchTab={setActiveTabKey} />)
      },
      {
        id: ELoginMethods.wxQrCode,
        tab: <Trans>微信扫码</Trans>,
        Component: memo(() => <FormWxQrCode />)
      },
      {
        id: ELoginMethods.wxServiceAccountQrCode,
        tab: <Trans>微信扫码</Trans>,
        Component: memo(() => <FormWxServiceAccountQrCode />)
      }
    ],
    []
  );

  const footerLogos = [
    {
      url: logoMuseTransfer,
      tip: '传输工具',
      href: MUSE_TRANSFER_URL,
      appId: 'MuseTransfer'
    },
    {
      url: logoMuseArt,
      tip: '艺术展示工具',
      href: MUSE_ART_URL,
      appId: 'MuseArt'
    },
    {
      url: logoMuseLink,
      tip: '名片展示工具',
      href: MUSE_LINK_URL,
      appId: 'MuseLink'
    }
  ];

  const availableLoginMethods = appsConfig['museDam'].availableLoginMethods;
  const filteredTabs = tabs.filter((tab) => availableLoginMethods.includes(tab.id));

  useEffect(() => {
    if (filteredTabs?.[0].id && typeof activeTabKey === 'undefined') setActiveTabKey(filteredTabs?.[0].id);
  }, [activeTabKey, filteredTabs]);

  const onTabClick = useCallback((key: string) => {
    setActiveTabKey(key);
  }, []);

  async function scanSuccess(code: string, uuid?: string) {
    closeAppModal();
    refreshModel();
    const targetUrl = getRedirectUrlFromUrl() || window.$config['MUSEDAM_LOGIN_REDIRECT_URL'] || window.location.href;
    serviceGetThirdSSOToken(code, uuid)
      .then(async (res) => {
        trackVisitorRegisterStepLoginSuccess();
        setSSOCookies(res);
        const parmas = {
          appUrl: addParam(res.targetUrl || targetUrl, { ssoTag: inputName }).href,
          authCode: res.code,
          loginJumpType: LOGIN_JUMP_TYPE.ORIGIN_WINDOW
        };
        handleRedirectToApp(parmas);
      })
      .catch((err) => {
        flushUserCookies();
        message.error('发生错误，请尝试重新登录');
        console.error(err);
      });
  }
  function handleNameClick() {
    if (!appConfig) return;
    tracer.track({
      event_type_code: 'Muse_Union_Login_Product_Click',
      event_properties: {
        location: 'product_name',
        product: appConfig.appId
      }
    });

    if (appConfig.introductionUrl) {
      window.open(appConfig.introductionUrl);
    }
  }
  function okCb(id: ELoginType) {
    // 获取当前页面的URL对象
    const url = new URL(window.location.href);

    // 保存当前的searchParams
    const searchParams = new URLSearchParams(url.search);
    history.replace(`/login/third_sso/${id}?${searchParams.toString()}`);
    if (!isCheck) {
      changeShakeRadio(true);
    } else {
      setCurrentLoginType(id);
    }
  }
  async function handelOkCb(val: string, type?: string) {
    const cType = type || currentLoginType;
    const list = await serviceGetSSOList({
      systemType: cType,
      tag: val
    });
    if (list.length) {
      const id = list[0].id;
      const result2 = await serviceLoginBySSOList(id);
      if (loginType === ELoginType.feishu && getPlatformType() === PlatformTypeEnum.Mobile) {
        window.location.replace(result2);
      } else if (getContainerType() === ContainerTypeEnum.SystemBrowser) {
        const data = list[0];
        // 获取当前页面的URL对象
        const url = new URL(window.location.href);
        // 保存当前的searchParams
        const searchParams = new URLSearchParams(url.search);
        history.replace(`/login/third_sso/${cType}/${val}?${searchParams.toString()}`);
        showAppModal({ iframeUrl: result2, type: cType, data });
      } else {
        window.location.replace(result2);
      }
      // if (currentLoginType === ELoginType.dingding) {
      //   const data = list[0];
      //   showAppModal({ iframeUrl: result2, type: currentLoginType, data });
      //   setTimeout(() => {
      //     window.DTFrameLogin(
      //       {
      //         id: 'sso_scan_image_element',
      //         width: 250,
      //         height: 250
      //       },
      //       {
      //         redirect_uri: encodeURIComponent(location.origin),
      //         client_id: data.clientId,
      //         scope: 'openid',
      //         response_type: 'code',
      //         state: 'xxxxxxxxx',
      //         prompt: 'consent'
      //       },
      //       (loginResult) => {
      //         const { redirectUrl, authCode, state } = loginResult;
      //         // 这里可以直接进行重定向
      //         window.location.href = data.redirectUrl + '?code=' + authCode;
      //         // 也可以在不跳转页面的情况下，使用code进行授权
      //         console.log(authCode);
      //       },
      //       (errorMsg) => {
      //         // 这里一般需要展示登录失败的具体原因
      //         alert(`Login Error: ${errorMsg}`);
      //       }
      //     );
      //   });
      // } else {
      //   showAppModal({ iframeUrl: result2, type: currentLoginType, data: list[0] });
      // }
    } else {
      message.error('识别信息有误,请联系管理员');
    }
  }

  function handelCancel() {
    // 获取当前页面的URL对象
    const url = new URL(window.location.href);
    // 保存当前的searchParams
    const searchParams = new URLSearchParams(url.search);
    history.replace(`/login?${searchParams.toString()}`);
    setCurrentLoginType(ELoginType.default);
  }
  function handleFooterLogoClick(e: MouseEvent<HTMLAnchorElement>, item: IFooterLogo) {
    e.preventDefault();

    tracer.track({
      event_type_code: 'Muse_Union_Login_Product_Click',
      event_properties: {
        location: 'product_logo',
        product: item.appId
      }
    });

    if (item.href) {
      window.open(item.href);
    }
  }
  if (!appConfig) {
    return <div />;
  }

  return (
    <main className={$style.login_page} data-test="login-page">
      <BizContactUs open={open} setOpen={setOpen} />
      <BizPhoneForceBindModal />
      <div className={$style.left}>
        <AppAuthLoginCarousel />
      </div>
      <div className={classNames($style.right, 'justify-between flex flex-col')}>
        <div className="flex flex-col items-center">
          {/* title */}
          <div className={$style.right__logo} onClick={handleNameClick}>
            <img src={logos[appName!]} />
          </div>
          {/* forms */}
          {currentLoginType === ELoginType.default && (
            <Tabs
              className={$style.right__forms_tabs}
              tabBarGutter={0}
              activeKey={activeTabKey}
              onTabClick={onTabClick}
            >
              {filteredTabs.map((tab) => {
                return (
                  <Tabs.TabPane
                    tab={
                      <div
                        data-test={tab.id}
                        className={$style.right__forms_tabs__tab_title}
                        style={{ width: 242 / filteredTabs.length }}
                      >
                        {tab.tab}
                      </div>
                    }
                    key={tab.id}
                  >
                    <tab.Component />
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          )}
          {currentLoginType !== ELoginType.default && (
            <AppThirdSSO
              openContactUs={setOpen}
              okCb={handelOkCb}
              cancelCb={handelCancel}
              className="mt-[30px]"
              scanSuccess={scanSuccess}
            />
          )}
          {currentLoginType === ELoginType.default && <BizSsoListLogin okCb={okCb} />}
        </div>

        {/* footer */}
        {footerLogos.length > 0 && (
          <div className={$style.right__footer}>
            <div className="w-full">
              <Divider type="horizontal" plain>
                <Trans>了解更多MUSE产品 {currentLoginType}</Trans>
              </Divider>
            </div>
            <div className={$style.right__footer__logo_wrapper}>
              {footerLogos.map((item, i) => {
                const { url, tip, href } = item;
                return (
                  <a
                    className={$style.right__footer__logo}
                    href={href}
                    key={i}
                    onClick={(e) => handleFooterLogoClick(e, item)}
                  >
                    <Tooltip title={tip}>
                      <img src={url} />
                    </Tooltip>
                  </a>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </main>
  );
}
