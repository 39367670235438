/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import type { AxiosInstance } from 'axios';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import 'nprogress/nprogress.css';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { History } from 'history';
import { flushUserCookies, getTokens } from 'shared/utils/cookie';
import { ErrorAppResponse, ErrorServerSide, Response_Errors } from '@/services/errors';
import isAccessibleRoute from '@/routes/routesPermission';

const requestTransfer = axios.create({
  baseURL: `${window.$config['MUSEDAM_USERCENTER_URL']}/transfer`
});

const whiteListCode = new Set([0, 200]);

const errHandler = (error: any) => {
  // eslint-disable-next-line eqeqeq
  if (error.status == 500) {
    return Promise.reject(new ErrorServerSide(error?.message || error));
  }
  return Promise.reject(error);
};

export function useInitTransferAxios() {
  const history = useHistory<any>();
  useEffect(() => {
    const eject = setupInterceptors(requestTransfer, history);
    return eject;
  }, [history]);
}

const responseInterceptor =
  (options: { history: History; flushUserCookies: () => void; isAccessibleRoute: () => boolean }) =>
  (v: AxiosResponse) => {
    const code = parseInt(v.data.code, 10);

    if (!whiteListCode.has(code)) {
      return Promise.reject(new (Response_Errors[code] || ErrorAppResponse)(code, v.data.message));
    }

    v.data = v.data.result;
    return v;
  };

type TGetTokens = typeof getTokens;
const requestInterceptor = (options: { getTokens: TGetTokens }) => (v: AxiosRequestConfig) => {
  const { getTokens } = options;
  const tokens = getTokens();
  if (tokens) {
    v.headers['x-global-user-id'] = tokens.userId;
    v.headers['x-sso-token'] = tokens.token;
    v.headers['x-user-pool-id'] = tokens.userPoolId;
  }
  return v;
};

function setupInterceptors(request: AxiosInstance, history: History) {
  const id1 = request.interceptors.request.use(requestInterceptor({ getTokens }), errHandler);
  const id2 = request.interceptors.response.use(
    responseInterceptor({ history, flushUserCookies, isAccessibleRoute }),
    errHandler
  );
  return () => {
    request.interceptors.request.eject(id1);
    request.interceptors.response.eject(id2);
  };
}

export const __exportedForTesting = {
  requestInterceptor,
  responseInterceptor
};

export default requestTransfer;
