/**
 * tezign ownership
 * @owner weilingfeng, yangliangfei
 * @team Muse
 */
import { useForm } from 'antd/es/form/Form';
import { Button, ButtonProps, Form, Input, message, Radio } from 'antd';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { flushUserCookies, setSSOCookies } from 'shared/utils/cookie';
import { trackVisitorRegisterStepLoginSuccess } from 'shared/tracer/register-analysis';
import $style from '@/components-view/AppAuth/AppAuth.module.less';
import { checkLoginStateThenRedirectIfValid } from '@/utils/login';
import { serviceLoginByPhone, serviceSendSmsCode } from '@/services/authentication';
import { ErrorNotValidVerifyCode, ErrorWrongUserNameOrPASSWORD } from '@/services/errors';
import { PRIVACY_POLICY, USER_AGREEMENT } from '@/utils/constants';
import { changeShakeRadio, toggleCheck, useStore } from '@/store/app-store';

export const BtnSendVerificationCode = (props: { onClick: () => Promise<number | undefined> } & ButtonProps) => {
  const { onClick, ...rest } = props;
  const timer = useRef(-1);
  const [count, setCount] = useState(-1);
  const isCounting = count > 0;

  useEffect(() => {
    if (!isCounting) {
      clearInterval(timer.current);
    }
  }, [isCounting]);

  async function handleClick() {
    const res = await onClick();
    if (res === -1) return;

    setCount(60);
    timer.current = setInterval(() => setCount((c) => c - 1), 1000);
  }

  const renderText = () => {
    if (isCounting) {
      return <span>重新发送{count}</span>;
    }
    return <Trans>获取验证码</Trans>;
  };

  return (
    <Button
      className={$style.send_code_btn}
      size="small"
      type="text"
      disabled={isCounting}
      onClick={handleClick}
      {...rest}
    >
      {renderText()}
    </Button>
  );
};

export function FormPhoneWithCode() {
  const [form] = useForm();
  const { isCheck: radioChecked, isShakeRadio: shakeRadio } = useStore();
  const history = useHistory();
  async function handleSendSmsCode() {
    const phone = form.getFieldValue('phone');

    if (!phone) {
      message.error(<Trans>手机号不能为空</Trans>);
      return -1;
    }

    const isValidPhone = /^(1[3-9])\d{9}$/.test(phone);
    if (!isValidPhone) {
      message.error(<Trans>请输入正确的手机号</Trans>);
      return -1;
    }

    await serviceSendSmsCode(phone);
    message.success(<Trans>验证码已发送</Trans>);
  }

  async function handleLoginByPhone() {
    try {
      const phone = form.getFieldValue('phone');
      const verifyCode = form.getFieldValue('verifyCode');

      if (!radioChecked) {
        changeShakeRadio(true);

        return;
      }

      if (!phone) {
        message.error(<Trans>手机号不能为空</Trans>);
        return;
      }

      if (!/^(1[3-9])\d{9}$/.test(phone)) {
        message.error(<Trans>请输入正确的手机号</Trans>);
        return;
      }

      if (!verifyCode) {
        message.error(<Trans>验证码不能为空</Trans>);
        return;
      }

      const res = await serviceLoginByPhone(phone, verifyCode);
      if (!res || !res.globalUserId || !res.ssoToken) {
        message.error(<Trans>发生错误，请尝试重新登录</Trans>);
        return;
      }
      trackVisitorRegisterStepLoginSuccess();

      setSSOCookies({ globalUserId: res.globalUserId, ssoToken: res.ssoToken, userPoolId: res.userPoolId });
      checkLoginStateThenRedirectIfValid('', history).catch((err) => {
        message.error(<Trans>发生错误，请尝试重新登录</Trans>);
        console.error(err);
      });
    } catch (err: any) {
      if (err instanceof ErrorNotValidVerifyCode) {
        message.error(i18n.t('验证码不正确'));
        return;
      }

      if (err instanceof ErrorWrongUserNameOrPASSWORD) {
        message.error(i18n.t('用户名或密码错误'));
        return;
      }
      flushUserCookies();
      message.error(i18n.t('发生错误，请尝试重新登录'));
    }
  }

  function handleRadioClick() {
    toggleCheck();
  }

  function handleAnimationEnd() {
    changeShakeRadio(false);
  }

  return (
    <div>
      <Form form={form} className={$style.phone_code_login_form}>
        <Form.Item name="phone" noStyle>
          <Input
            className="mt-[24px]"
            prefix={<div className={$style.phone_input__suffix}>+86</div>}
            placeholder={i18n.t('请输入手机号')}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item name="verifyCode" noStyle>
          <Input
            placeholder={i18n.t('请输入验证码')}
            className="mt-[16px]"
            autoComplete="off"
            suffix={<BtnSendVerificationCode onClick={handleSendSmsCode} />}
          />
        </Form.Item>
        <div className={$style.login_description}>
          <Trans>新手机号验证后，将自动注册MUSE账号</Trans>
        </div>
        <Button htmlType="submit" className={$style.submit_btn} type="primary" onClick={handleLoginByPhone}>
          <Trans>登录/注册</Trans>
        </Button>
        <div
          className={classNames($style.login_agreement, { [$style.shake]: shakeRadio })}
          onAnimationEnd={handleAnimationEnd}
        >
          <Radio checked={radioChecked} onClick={handleRadioClick} />
          <Trans>
            我已阅读且同意
            <a href={USER_AGREEMENT} className="privacy" target="_blank">
              用户协议
            </a>
            和
            <a className="privacy" href={PRIVACY_POLICY} target="_blank">
              隐私条款
            </a>
          </Trans>
        </div>
      </Form>
    </div>
  );
}
