/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import { EMuseApps } from 'shared/types';
import { useEffect } from 'react';
import defineStore from '@/store/defineStore';
import { appIdToAppNameMap, IAppConfig } from '@/config/apps-config';
import { serviceGetAppsConfig } from '@/services/authentication';
import { EAppIds, IFooterLogo, IUser } from '@/types';
import { serviceGetUser } from '@/services/manage';

interface IStore {
  appName?: EMuseApps;
  appsConfig: IAppConfig[];
  isMobile: boolean;
  user?: IUser;
  isCheck: boolean;
  isShakeRadio: boolean;
}

interface IStoreGetters {
  appId?: string;
  appConfig?: IAppConfig;
  footerLogos: IFooterLogo[];
}

function getIsMobile() {
  return window.innerWidth < 768;
}

window.addEventListener('resize', () => {
  updateStore((st) => {
    return {
      ...st,
      isMobile: getIsMobile()
    };
  });
});

export const {
  subject$,
  hook: useStore,
  updateStore,
  readonlyStateRef: readonlyStoreRef
} = defineStore<IStore, IStoreGetters>(
  {
    appName: EMuseApps.museDam,
    appsConfig: [],
    isMobile: getIsMobile(),
    user: undefined,
    isCheck: false,
    isShakeRadio: false
  },
  {
    name: 'AppStore',
    getters: {
      appId: {
        getter(st) {
          return st.appName ? EAppIds[st.appName] : undefined;
        }
      },
      appConfig: {
        getter(st) {
          const appName = st.appName;
          return appName ? st.appsConfig.find((c) => c.appId === EAppIds[appName]) : undefined;
        }
      },
      footerLogos: {
        getter(st) {
          return st.appsConfig
            .filter((a) => a.appId !== st.appId && a.appId !== 'tz_minidam')
            .map((a) => ({ appId: a.appId, url: a.footerLogo, tip: a.footerLogoTip, href: a.introductionUrl }));
        }
      }
    }
  }
);

export function changeShakeRadio(bool: boolean) {
  updateStore((st) => {
    return {
      ...st,
      // appsConfig: Object.values(appsConfig) // 调配置用
      isShakeRadio: bool
    };
  });
}
export function toggleCheck() {
  updateStore((st) => {
    return {
      ...st,
      // appsConfig: Object.values(appsConfig) // 调配置用
      isCheck: !st.isCheck
    };
  });
}

export function toggleIsCheck() {
  updateStore((st) => {
    return {
      ...st,
      // appsConfig: Object.values(appsConfig) // 调配置用
      isCheck: true
    };
  });
}

export function useInitAppsConfig() {
  useEffect(() => {
    serviceGetAppsConfig().then((configs) => {
      updateStore((st) => {
        return {
          ...st,
          // appsConfig: Object.values(appsConfig) // 调配置用
          appsConfig: configs.filter((c) => c.appId !== 'tz_minidam')
        };
      });
    });
  }, []);
}

export function setStoreAppNameFromAppId(appId: EAppIds) {
  updateStore((st) => ({
    ...st,
    appName: appIdToAppNameMap[appId]
  }));
}

export function refetchUser() {
  return serviceGetUser().then((data) => {
    updateStore((st) => ({
      ...st,
      user: data
    }));
  });
}
