/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import { distinctUntilChanged, Observable } from 'rxjs';
import { useEffect, useState } from 'react';
import { useCreation } from 'ahooks';
import { isObject } from 'lodash';

/**
 * 比较两个状态是否"相等"
 * @param prev
 * @param current
 * @param keys
 */
const compareState = <T>(prev: T, current: T, keys: (keyof T)[]) => {
  if (prev === current) {
    return true;
  }
  if (!isObject(prev) || keys.length < 1) return false;
  return keys.every((key) => Object.is(prev[key], current[key]));
};

export interface IRxStateOptions<T, Key extends keyof T = keyof T> {
  /**
   * T不为对象时不需要此配置
   */
  keys?: Key[];
}

export function useRxState<T>(subject: Observable<T>, initialStateFactory: () => T, options?: any): T;
export function useRxState<T, Key extends keyof T>(
  subject: Observable<T>,
  initialStateFactory: () => T,
  options: IRxStateOptions<T, Key>
): Pick<T, Key>;
export function useRxState<T, Key extends keyof T>(
  observable$: Observable<T>,
  initialStateFactory: () => T,
  options: IRxStateOptions<T, Key> = {}
): Partial<T> {
  const initialState = useCreation<T>(() => initialStateFactory(), []);
  const [state, setState] = useState(initialState);
  const { keys = [] } = options;
  useEffect(() => {
    const sub = observable$
      .pipe(distinctUntilChanged((prev, current) => compareState(prev, current, keys)))
      .subscribe((st) => {
        setState(st);
      });
    return sub.unsubscribe.bind(sub);
    // 只运行一次
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);
  return state;
}

export const exportedForTesting = {
  compareState,
  useRxState
};
