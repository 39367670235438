/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */

import { EMuseApps } from 'shared/types';
import { IThemeVars } from 'shared/theme-config';
import logoMuseDAM from '@/assets/logo-musedam.png';
import logoMuseTransfer from '@/assets/logo-musetransfer.png';
import { EAppIds, ELoginMethods } from '@/types';

export const appIdToAppNameMap = {
  [EAppIds.museTransfer]: EMuseApps.museTransfer,
  [EAppIds.museDam]: EMuseApps.museDam
};

export const appIdToAppNameInUI = {
  [EAppIds.museTransfer]: 'MuseTransfer',
  [EAppIds.museDam]: 'MuseDAM'
};

export const appIdToManageMenuLogo = {
  [EAppIds.museTransfer]: logoMuseTransfer,
  [EAppIds.museDam]: logoMuseDAM
};

export interface IAppConfig {
  appId: string;
  footerLogo: string;

  footerLogoTip: string;

  introductionUrl: string;

  /**
   * 轮播图
   */
  carouselMaterials: {
    url: string;
    type: 'video' | 'image';
  }[];

  // 登录后重定向链接
  defaultTargetUrl: string;

  availableLoginMethods: ELoginMethods[];

  themeConfig: IThemeVars;

  disableNavigateWithTzUcCode?: boolean;
}

const appsConfig: Record<EMuseApps, IAppConfig> = {
  [EMuseApps.museTransfer]: {
    appId: EAppIds.museTransfer,
    introductionUrl: 'https://musetransfer.com',
    footerLogo:
      'https://itg-tezign-files.tezign.com/b57a7f6febad21181f09310f16002afa.svg?Expires=2280401632&OSSAccessKeyId=LTAI5tDubGKMRMroG8MuTLZo&Signature=9V3SBh8jJXOwiw1LDV8mPf%2B6oMU%3D',
    footerLogoTip: '传输工具',
    carouselMaterials: [
      {
        url: 'https://itg-tezign-files.tezign.com/sop/public/432/0130a0953/PIC1.png',
        type: 'image'
      },
      {
        url: 'https://itg-tezign-files.tezign.com/sop/public/432/0130a0953/PIC2.png',
        type: 'image'
      },
      {
        url: 'https://itg-tezign-files.tezign.com/sop/public/432/0130a0953/PIC3.png',
        type: 'image'
      },
      {
        url: 'https://itg-tezign-files.tezign.com/sop/public/432/0130a0953/PIC4.png',
        type: 'image'
      },
      {
        url: 'https://itg-tezign-files.tezign.com/sop/public/432/0130a0953/PIC5.png',
        type: 'image'
      }
    ],
    defaultTargetUrl: 'https://musetransfer.com',
    availableLoginMethods: [ELoginMethods.phoneWithCode, ELoginMethods.wxQrCode, ELoginMethods.orgEmailCode],
    disableNavigateWithTzUcCode: true,
    themeConfig: {
      successColor: 'rgba(0, 214, 143, 1)',
      warningColor: 'rgba(255, 170, 0, 1)',
      errorColor: 'rgba(255, 61, 113, 1)',
      primaryColor: '#5544DE',
      infoColor: '#5544DE',
      processingColor: '#5544DE',

      'basic-0': 'rgba(255, 255, 255, 1)',
      'basic-1': 'rgba(247, 249, 252, 1)',
      'basic-2': 'rgba(237, 241, 247, 1)',
      'basic-3': 'rgba(228, 233, 242, 1)',
      'basic-4': '#D0D5E4',
      'basic-5': '#9AA1B0',
      'basic-6': '#5D6C8B',
      'basic-7': 'rgba(46, 58, 89, 1)',
      'basic-8': 'rgba(25, 32, 56, 1)',
      'basic-9': 'rgba(21, 26, 48, 1)',
      'basic-10': 'rgba(16, 20, 38, 1)',

      'primary-1': '#3C2BC5',
      'primary-2': '#3C2BC5',
      'primary-3': '#3C2BC5',
      'primary-4': '#3C2BC5',
      'primary-5': '#3C2BC5',
      'primary-6': '#5544DE',
      'primary-7': '#6F64C8',
      'primary-8': '#6F64C8',
      'primary-9': '#6F64C8',

      'text-color': '#2A3243',
      'text-default': '#9AA1B0',
      'text-color-description': '#5D6C8B',
      'tab-text-color': '#9AA1B0',
      'tab-active-text-color': '#2A3243',

      'input-focus-shadow': 'rgba(85, 68, 222, 0.2)'
    }
  },

  [EMuseApps.museDam]: {
    appId: EAppIds.museDam,
    introductionUrl: `${window.$config['MUSEDAM_LOGIN_REDIRECT_URL']}/landing`,
    footerLogo:
      'https://itg-tezign-files.tezign.com/e48fd6a07535c14486445fda17425cdc.svg?Expires=2280401731&OSSAccessKeyId=LTAI5tDubGKMRMroG8MuTLZo&Signature=J4zrRJs9TSR3y%2FWl%2FSgbV6FKry0%3D',
    footerLogoTip: '素材收集管理器',
    carouselMaterials: [
      {
        url: 'https://musedam-assets.oss-cn-beijing.aliyuncs.com/92ffdd4e044fa9079cd5b2cdb1707054.png?Expires=2281611296&OSSAccessKeyId=LTAI5tDubGKMRMroG8MuTLZo&Signature=K%2BcZJna2cKSiGGaKrEaBy2mXPiU%3D',
        type: 'image'
      },
      {
        url: 'https://musedam-assets.oss-cn-beijing.aliyuncs.com/d1b22fcdf645e966619711acfb408db4.png?Expires=2281611296&OSSAccessKeyId=LTAI5tDubGKMRMroG8MuTLZo&Signature=GnYkwsCgAHZOMQPPeQsW7bwgSIc%3D',
        type: 'image'
      },
      {
        url: 'https://musedam-assets.oss-cn-beijing.aliyuncs.com/5dd416b588b20ecb1475ac6b572c7d6f.png?Expires=2281611296&OSSAccessKeyId=LTAI5tDubGKMRMroG8MuTLZo&Signature=B%2FTsrCIK%2BSxP6YgoOtCFEq0t3hs%3D',
        type: 'image'
      },
      {
        url: 'https://musedam-assets.oss-cn-beijing.aliyuncs.com/f7445d0c7888aff7ef257532ab716ac7.png?Expires=2281611296&OSSAccessKeyId=LTAI5tDubGKMRMroG8MuTLZo&Signature=nTkIu509DZyxXq5%2FVLHBpHd3BRI%3D',
        type: 'image'
      },
      {
        url: 'https://musedam-assets.oss-cn-beijing.aliyuncs.com/a5a9c675080664938b70aa956ae8664a.png?Expires=2281611296&OSSAccessKeyId=LTAI5tDubGKMRMroG8MuTLZo&Signature=StSFn3cJxi8GctecVD3l4ikss3c%3D',
        type: 'image'
      }
    ],
    defaultTargetUrl: `${window.$config['MUSEDAM_LOGIN_REDIRECT_URL']}`,
    availableLoginMethods: [ELoginMethods.phoneOrEmailWithCode, ELoginMethods.password, ELoginMethods.wxQrCode],
    themeConfig: {
      primaryColor: '#5544DE',
      infoColor: '#5544DE',
      successColor: 'rgba(0, 214, 143, 1)',
      warningColor: 'rgba(255, 170, 0, 1)',
      errorColor: 'rgba(255, 61, 113, 1)',
      processingColor: '#5544DE',

      'basic-0': 'rgba(255, 255, 255, 1)',
      'basic-1': 'rgba(247, 249, 252, 1)',
      'basic-2': 'rgba(237, 241, 247, 1)',
      'basic-3': 'rgba(228, 233, 242, 1)',
      'basic-4': 'rgba(197, 206, 224, 1)',
      'basic-5': 'rgba(143, 155, 179, 1)',
      'basic-6': 'rgba(34, 43, 69, 1)',
      'basic-7': 'rgba(46, 58, 89, 1)',
      'basic-8': 'rgba(25, 32, 56, 1)',
      'basic-9': 'rgba(21, 26, 48, 1)',
      'basic-10': 'rgba(16, 20, 38, 1)',

      'primary-1': '#598BFF',
      'primary-2': '#598BFF',
      'primary-3': '#598BFF',
      'primary-4': '#598BFF',
      'primary-5': '#598BFF',
      'primary-6': '#3366FF',
      'primary-7': '#274BDB',
      'primary-8': '#274BDB',
      'primary-9': '#274BDB',

      'text-color': '#2E3A59',
      'text-default': '#8F9BB3',
      'text-color-description': '#2E3A59',

      'tab-text-color': '#8F9BB3',
      'tab-active-text-color': '#3366FF',
      'input-focus-shadow': 'rgba(51, 102, 255, 0.2)'
    }
  }
};

export default appsConfig;
