/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
// 增加sandbox属性以允许微信iframe跨域弹窗
!(function (a, b, c) {
  function d(a) {
    let c = 'default';
    a.self_redirect === !0 ? (c = 'true') : a.self_redirect === !1 && (c = 'false');
    const d = b.createElement('iframe');
    let e =
      'https://account.musetransfer.com/wechat-oauth?appid=' +
      a.appid +
      '&scope=' +
      a.scope +
      '&redirect_uri=' +
      a.redirect_uri +
      '&state=' +
      a.state +
      '&login_type=jssdk&self_redirect=true' +
      '&styletype=' +
      (a.styletype || '') +
      '&sizetype=' +
      (a.sizetype || '') +
      '&bgcolor=' +
      (a.bgcolor || '') +
      '&rst=' +
      (a.rst || '');
    (e += a.style ? '&style=' + a.style : ''),
      (e += a.href ? '&href=' + a.href : ''),
      (d.src = e),
      (d.frameBorder = '0'),
      (d.allowTransparency = 'true'),
      (d.scrolling = 'no'),
      (d.width = '300px'),
      (d.height = '400px');
    d.sandbox = 'allow-scripts allow-top-navigation allow-same-origin';

    const f = b.getElementById(a.id);
    (f.innerHTML = ''), f.appendChild(d);
  }
  a.WxLogin = d;
})(window, document);
