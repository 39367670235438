/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */

export const __PW__ = !!localStorage.getItem('__PW__');

// transfer 服务号微信Id
export const TRANSFER_WX_APPID = __PROD__ ? 'wx442ec54730781c2d' : 'wx2f0cb2a9cb3cf26a';

// muse 用户池 ID
export const MUSE_IDP_ID = 748;

export const USER_AGREEMENT = window.$config['MUSEDAM_LOGIN_REDIRECT_URL'] + '/terms';

export const PRIVACY_POLICY = window.$config['MUSEDAM_LOGIN_REDIRECT_URL'] + '/privacy';

export const MUSE_LINK_URL = 'https://muselink.cc/';
export const MUSE_ART_URL = 'https://art.musetransfer.com/';
export const MUSE_PAIN_URL = 'https://muselink.cc/';
export const MUSE_TRANSFER_URL = 'https://musetransfer.com/';
