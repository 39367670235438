/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */

export enum EMuseApps {
  museTransfer = 'museTransfer',
  museDam = 'museDam'
}

export enum EMuseAppPaths {
  museTransfer = 'muse-transfer',
  museDam = 'muse-dam'
}

export const museAppPathToName: Record<EMuseAppPaths, EMuseApps> = {
  [EMuseAppPaths.museTransfer]: EMuseApps.museTransfer,
  [EMuseAppPaths.museDam]: EMuseApps.museDam
};
