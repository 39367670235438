/**
 * tezign ownership
 * @owner weilingfeng
 * @team M1
 */
import React from 'react';
import withAntdSvgIcon from './withAntdSvgIcon';

import { ReactComponent as CheckedCircleOutlinedSvg } from './svgs/muse-checked-circle-outlined.svg';
import { ReactComponent as MuseLeftCircleOutlinedSvg } from './svgs/muse-left-circle.svg';
import { ReactComponent as WxLogoSvg } from './svgs/wxLogo.svg';

export const IconMuseLeftCircleOutlined = withAntdSvgIcon(MuseLeftCircleOutlinedSvg, 'IconMuseLeftCircleOutlined');

export const IconCheckedCircleOutlined = withAntdSvgIcon(CheckedCircleOutlinedSvg, 'IconCheckedCircleOutlined');
export const IconWxLogoSvg = withAntdSvgIcon(WxLogoSvg, 'IconWx');
