/**
 * tezign ownership
 * @owner chenmingjie
 * @team m2
 */
import React, { FC } from 'react';
import { IconWxLogoSvg } from 'shared/icons';
import Divider from 'antd/lib/divider';
import { Modal } from 'antd';
import { defineStore } from 'shared/hooks/defineStore';
import classNames from 'classnames';
import classnames from 'classnames';
import $style from './index.module.less';
import contactUsPng from '@/assets/contactUs.jpeg';
import wxGroupPng from '@/assets/contactUs.png';
import { ELoginType } from '@/components-view/AppAuth/AppAuthLogin';
import dingdingSvg from '@/assets/dingdingLogo.svg';

interface IBizSSOScanModal {
  open: boolean;
  iframeUrl: '';
  type: ELoginType;
  refreshKey: number;
  data?: any;
  inputName: string;
}
const { hook: useModalState, updateStore: updateModalState } = defineStore(
  { open: false, type: ELoginType.default, iframeUrl: '', data: {}, refreshKey: 0, inputName: '' } as IBizSSOScanModal,
  {
    name: 'BizPhoneForceModal'
  }
);
export { useModalState, updateModalState };

export function showAppModal({ iframeUrl, type, data = {} }) {
  updateModalState((st) => ({
    ...st,
    iframeUrl,
    data,
    type,
    open: true
  }));
}

export function closeAppModal() {
  updateModalState((st) => ({
    ...st,
    iframeUrl: '',
    data: {},
    open: false
  }));
}
export const refreshModel = () => {
  updateModalState((st) => ({
    ...st,
    refreshKey: ++st.refreshKey
  }));
};
export const changeSSOTagname = (val: string) => {
  updateModalState((pre) => ({ ...pre, inputName: val }));
};
const BizSSOScan = () => {
  /*  ------------------------------------------react-state------------------------------------------ */
  const { open, iframeUrl, type, data, refreshKey } = useModalState();
  /* ------------------------------------------react-function------------------------------------------ */
  function cancel() {
    closeAppModal();
  }
  /* ------------------------------------------react-effect------------------------------------------ */
  /* ------------------------------------------react-component------------------------------------------ */
  return (
    <Modal
      onCancel={cancel}
      visible={open}
      title="扫码登录"
      className={classnames(type === ELoginType.qiwei && $style.qiwei)}
      width={type === ELoginType.dingding || type === ELoginType.qiwei ? 400 : 700}
      centered
      footer={null}
      closable={false}
    >
      <div key={refreshKey}>
        {type === ELoginType.dingding && (
          <>
            <span className="absolute left-[20px] top-[20px] z-20 text-[16px] font-media leading-[24px] ">
              扫码登录
            </span>
            <div
              className={classNames(
                ' flex items-center justify-center w-full bg-white overflow-hidden h-[325px] relative top-[-45px]'
              )}
            >
              <div className="border-t-[110px] border-r-[70px] border-l-[70px] absolute z-10 w-[360px] h-[400px] bg-clip-content border-white  pointer-events-none  border-solid" />
              <iframe
                scrolling="no"
                src={iframeUrl}
                className="w-full h-full border-none absolute overflow-hidden top-0 left-[-10px]"
              />
              {/* {type === ELoginType.dingding ? (
          <div className="flex flex-col items-center relative top-[-20px]">
            <span className="leading-4 relative top-[20px] flex items-center gap-[8px]">
              <img src={dingdingSvg} className="w-[32px] h-[32px] " />
              <span className="text-[20px] font-medium">钉钉登录</span>
            </span>
            <div id="sso_scan_image_element" className=" w-[250px] h-[250px] flex justify-center" />
            <p className="p3 m-0">请用钉钉扫描二维码登录</p>
            <p className="p3 m-0">"{data?.displayName ?? ''}"</p>
          </div>
        ) : (
          <iframe src={iframeUrl} className="w-full h-full border-none" />
        )} */}
            </div>
          </>
        )}
        {type !== ELoginType.dingding && (
          <div
            className={classNames(
              ' flex items-center justify-center w-full bg-white',
              type === ELoginType.feishu ? 'h-[600px]' : type === ELoginType.qiwei ? 'h-[390px]' : 'h-[320px]'
            )}
          >
            <iframe src={iframeUrl} className="w-full h-full border-none" />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default BizSSOScan;
