/**
 * tezign ownership
 * @owner weilingfeng
 * @team M1
 */
import React from 'react';
import Icon from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/es/components/Icon';
import classNames from 'classnames';
import 'shared/icons/index.scss';

export default function withAntdSvgIcon(Comp: IconComponentProps['component'], name: string) {
  function SvgIcon(props: Parameters<typeof Icon>[0]) {
    return (
      <Icon
        {...props}
        component={Comp}
        className={classNames(props.className, 'app-icon')}
        style={{ fontSize: props.size && props.size + 'px', ...props.style }}
      />
    );
  }

  SvgIcon.displayName = name;
  return SvgIcon;
}
