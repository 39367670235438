/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
export class ErrorSentryIgnore extends Error {
  constructor(message: string) {
    super('[sentry ignore] ' + message);
  }
}
