/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import React, { Suspense, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import 'shared/styles/shortcuts.less';
import './App.less';

import { BrowserRouter, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { History } from 'history';
import { useInitTracer } from 'shared/tracer/tracer';
import { defineStore } from 'shared/hooks/defineStore';
import { EMuseAppPaths } from 'shared/types';
import { getSSOGlobalUserId, getSSOToken, setSSOCookies, setXTokens } from 'shared/utils/cookie';
import { useInitAxios } from './services/requestTzUserCenter';
import { useInitTransferAxios } from './services/requestTransfer';
import SpinLoading from './components/loadings/SpinLoading';
import { saveXTokenBySSOToken, useCheckPermissionOnRouteChange } from './utils/login';
import useCheckNetwork from './hooks/useCheckNetwork';
import BizPhoneForceModal from './components-business/BizPhoneForceModal/BizPhoneForceModal';
import AppModalTipBind from './components-business/AppModalTipBind/AppModalTipBind';
import BizSSOScan from './components-business/BizSSOScan/BizSSOScan';
import { useInitAppsConfig } from '@/store/app-store';

const AppAuthentication = React.lazy(() => import('@/components-view/AppAuth/AppAuth'));
const AppThirdSsoRedirect = React.lazy(() => import('@/components-view/AppThirdSsoRedirect/AppThirdSsoRedirect'));
const AppAppWxSSO = React.lazy(() => import('@/components-view/AppWxSSO/AppWxSSO'));
const AppManage = React.lazy(() => import('@/components-view/AppManage/AppManage'));
const AppThirdSsoNoLogin = React.lazy(() => import('@/components-view/AppThirdSsoNoLogin/AppThirdSsoNoLogin'));
const AppThirdSsoNoLoginCb = React.lazy(() => import('@/components-view/AppThirdSsoNoLogin/AppThirdSsoNoLoginCb'));
const Suspense2 = (props: React.PropsWithChildren<any>) => {
  const loadingSimple = useMemo(() => <SpinLoading />, []);
  useInitAxios();
  useInitTransferAxios();
  const getLoading = () => {
    return loadingSimple;
  };
  return <Suspense fallback={getLoading()}>{props.children}</Suspense>;
};

const context = React.createContext(null);

const { hook: useGlobalHistoryState, updateStore: updateGlobalHistoryState } = defineStore(
  { location: undefined } as { location?: History['location'] },
  { name: 'GlobalHistoryState' }
);

function AuthWrapper({ children }: { children: React.ReactNode }) {
  useCheckPermissionOnRouteChange();
  const history = useHistory();
  useInitTracer({ history, userId: getSSOGlobalUserId(), production: __PROD__ });
  useEffect(() => {
    updateGlobalHistoryState((st) => ({
      ...st,
      location: history.location
    }));
    history.listen(() => {
      updateGlobalHistoryState((st) => ({
        ...st,
        location: history.location
      }));
    });
  }, [history]);
  return <context.Provider value={null}>{children}</context.Provider>;
}

function App() {
  useCheckNetwork();
  useInitAppsConfig();
  useEffect(() => {
    const searchParams = new URL(decodeURIComponent(location.href)).searchParams;
    const ssoToken = searchParams.get('ssoToken');
    const xToken = searchParams.get('xToken');
    const globalUserId = searchParams.get('guid');
    const userPoolId = searchParams.get('userPoolId');
    if (ssoToken && globalUserId && userPoolId && !getSSOToken()) {
      setSSOCookies({ ssoToken, globalUserId, userPoolId });
      if (xToken) {
        setXTokens({ xToken, globalUserId });
      } else {
        saveXTokenBySSOToken();
      }
    }
  }, []);

  return (
    <div className={classnames('app')}>
      <BrowserRouter>
        <AuthWrapper>
          <Suspense2>
            <Switch>
              <Route exact path="/login" component={AppAuthentication} />
              <Route exact path="/login/third_sso/:loginType/:ssoTag?" component={AppAuthentication} />

              <Route exact path="/login/third_sso_noLogin" component={AppThirdSsoNoLogin} />
              <Route exact path="/login/third_sso_noLogin_cb" component={AppThirdSsoNoLoginCb} />
              <Route exact path="/login/third_sso_redirect" component={AppThirdSsoRedirect} />

              <Route exact path="/login/wx-sso" component={AppAppWxSSO} />
              <Route exact path="/login/manage" component={AppManage} />
              <Redirect exact from="/auth/:appNamePath" to="/login" />
              <Redirect exact from="/manage/:appNamePath" to="/login/manage" />
              <Redirect exact from="/login/auth/:appNamePath" to="/login" />
              <Redirect exact from="/login/manage/:appNamePath" to="/login/manage" />
            </Switch>
          </Suspense2>
        </AuthWrapper>
      </BrowserRouter>
      <AppModalTipBind />
      <BizSSOScan />
    </div>
  );
}

export default App;
