/**
 * tezign ownership
 * @owner chenmingjie
 * @team m2
 */

import decodeAppId from '@tezign/foundation-common/es/utils/auth/decodeAppId';

export enum ContainerTypeEnum {
  SystemBrowser = 'SystemBrowser', // 系统浏览器
  DingTalkBrowser = 'DingTalkBrowser', // 钉钉内置浏览器
  QiyeWechatBrowser = 'QiyeWechatBrowser', // 企微内置浏览器
  WeChatBrowser = 'WeChatBrowser', // 微信内置浏览器
  FeishuBrowser = 'FeishuBrowser', // 飞书内置浏览器
  WeChatMiniProgram = 'WeChatMiniProgram', // 微信小程序
  QiyeWechatMiniProgram = 'QiyeWechatMiniProgram', // 企业微信小程序
  FeishuMiniProgram = 'FeishuMiniProgram' // 飞书小程序
}
export function getContainerType() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i)) {
    if (ua.match(/wxwork/i)) {
      return ContainerTypeEnum.QiyeWechatBrowser;
    } else {
      return ContainerTypeEnum.WeChatBrowser;
    }
  }
  if (ua.match(/dingtalk/i)) {
    return ContainerTypeEnum.DingTalkBrowser;
  }

  return ContainerTypeEnum.SystemBrowser;
}

export enum PlatformTypeEnum {
  Desktop = 'Desktop', // 桌面端
  Mobile = 'Mobile' // 移动端
}

export function getPlatformType() {
  const isMobile = /(iPhone|iPod|ios|iPad|Android|Windows Phone)/i.test(window.navigator.userAgent);
  return isMobile ? PlatformTypeEnum.Mobile : PlatformTypeEnum.Desktop;
}

export enum EndTypeEnum {
  WEB = 'WEB',
  H5 = 'H5',
  DINGTALK_CONTAINER = 'DINGTALK_CONTAINER',
  QIYEWECHAT_CONTAINER = 'QIYEWECHAT_CONTAINER'
}

export function getEndType() {
  const ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/dingtalk/i)) {
    return EndTypeEnum.DINGTALK_CONTAINER;
  }
  if (ua.match(/MicroMessenger/i) && ua.match(/wxwork/i)) {
    return EndTypeEnum.QIYEWECHAT_CONTAINER;
  }
  if (ua.match(/Mobi|Android|iPhone/i)) {
    return EndTypeEnum.H5;
  }
  return EndTypeEnum.WEB;
}

export function getURLParameters(url: string) {
  const queryObj = (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce((a, v) => {
    // @ts-ignore
    a[v.slice(0, v.indexOf('='))] = decodeURIComponent(v.slice(v.indexOf('=') + 1));
    return a;
  }, {});
  // @ts-ignore
  const app_id = queryObj?.app_id;
  const tryDecodeAppId = app_id ? decodeAppId(app_id) : '';
  if (tryDecodeAppId !== '') {
    // @ts-ignore
    queryObj.app_id = tryDecodeAppId;
  }
  return queryObj;
}

export function parseUrlWithQueryParams(url: string, params: Record<string, string>) {
  const queryString = Object.keys(params)
    .map((key) => {
      const val = params[key];
      if (val) {
        return `${encodeURIComponent(key)}=${encodeURIComponent(val)}`;
      }
      return '';
    })
    .filter(Boolean)
    .join('&');

  if (queryString) {
    return `${url}?${queryString}`;
  }
  return url;
}

export function addParamsToUrl(url: string, params: Record<string, string>) {
  const [pathAddQueryPart = '', hashPart = ''] = url.split('#');
  const [path = '', queryStr = ''] = pathAddQueryPart.split('?');
  const [hashPath = '', hashQueryStr = ''] = hashPart.split('?');
  const queryParams = getURLParameters(queryStr);
  const hashPartParams = getURLParameters(hashQueryStr);
  const pathAndQuery = parseUrlWithQueryParams(path, {
    ...queryParams,
    ...params
  });
  const hashAndQuery = parseUrlWithQueryParams(hashPath, hashPartParams);
  if (hashAndQuery !== '') {
    return pathAndQuery + '#' + hashAndQuery;
  }
  return pathAndQuery;
}
