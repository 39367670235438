/**
 * tezign ownership
 * @owner yangliangfei
 * @team muse transfer
 */
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { ReloadOutlined } from '@ant-design/icons';
import { setSSOCookies } from 'shared/utils/cookie';
import { serviceCheckWxQrCode, serviceGetSSOWxQrCode } from '@/services/authentication';
import $style from '@/components-view/AppAuth/AppAuth.module.less';
import { PRIVACY_POLICY, USER_AGREEMENT } from '@/utils/constants';
import { saveXTokenBySSOToken } from '@/utils/login';

const MUSE_TRANSFER_URL = 'https://musetransfer.com';

export function FormWxServiceAccountQrCode() {
  const sceneStr = useRef('');
  const checkStatusTimer = useRef(-1);
  const [qrCode, setQrcode] = useState('');
  const [scanStatus, setScanStatus] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setQrCode();
    return () => {
      clearTimeout(checkStatusTimer.current);
    };
  }, []);

  async function setQrCode() {
    const res = await serviceGetSSOWxQrCode();
    if (!res?.sceneStr || !res.url) return;

    sceneStr.current = res.sceneStr;
    setQrcode(res.url);
    setScanStatus(1);
    checkStatusTimer.current = setTimeout(checkStatus, 1000);
  }

  async function checkStatus() {
    const res = await serviceCheckWxQrCode(sceneStr.current);
    setScanStatus(res);
    // 1: 未扫描，2: 已扫描
    if (res === 1 || res === 2) {
      checkStatusTimer.current = setTimeout(checkStatus, 1000);
    } else if (typeof res === 'object' && res.globalUserId && res.ssoToken) {
      setSSOCookies({ globalUserId: res.globalUserId, ssoToken: res.ssoToken, userPoolId: res.userPoolId });
      await saveXTokenBySSOToken();
      window.location.replace(MUSE_TRANSFER_URL);
    }
  }

  async function handleRefreshQrcode() {
    setLoading(true);
    await setQrCode();
    setLoading(false);
  }

  return (
    <div className={$style.qrcode_area}>
      <div className={$style.qrcode_wrapper}>
        {qrCode ? <img className={$style.qr_code} src={qrCode} /> : null}
        <div
          className={classNames($style.expire_tip, {
            [$style.tip_show]: scanStatus === -1
          })}
          onClick={handleRefreshQrcode}
        >
          <ReloadOutlined
            className={classNames($style.expire_icon_reload, {
              [$style.rotate]: loading
            })}
          />
          <span className={$style.expire_tip_text}>刷新二维码</span>
        </div>
        <div
          className={classNames($style.expire_tip, {
            [$style.tip_show]: scanStatus === 2
          })}
        >
          <p className={$style.subscribe_tip_text}>
            请关注公众号
            <br />
            并授权登录
          </p>
          <p className={$style.subscribe_tip_reload} onClick={handleRefreshQrcode}>
            遇到问题？请点击刷新
            <ReloadOutlined
              className={classNames($style.subscribe_icon_reload, {
                [$style.rotate]: loading
              })}
            />
          </p>
        </div>
      </div>
      <div className={$style.desc}>请使用微信扫描二维码</div>
      <div className={$style.tip}>
        扫码表示您已阅读且同意
        <a href={USER_AGREEMENT} className="privacy" target="_blank">
          用户协议
        </a>
        和
        <a className="privacy" href={PRIVACY_POLICY} target="_blank">
          隐私条款
        </a>
      </div>
    </div>
  );
}
