/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import React, { useEffect, useImperativeHandle, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RedoOutlined } from '@ant-design/icons';
import './wxLogin';
import './WxQrCode.less';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { IconCheckedCircleOutlined } from 'shared/icons';
import { EMuseApps } from 'shared/types';
import { useStore } from '@/store/app-store';
import { __PW__ } from '@/utils/constants';
import { serviceGetSSOBindWxQrCode } from '@/services/authentication';
import { isWechatBrowser } from '@/utils/browser';
import useQuery from '@/hooks/useQuery';

const WxQrCode = React.forwardRef(function WxQrCode(
  props: {
    onSuccess: (code: string) => void;
    onFail?: () => void;
    className?: string;
    from?: 'login' | 'bind';
  },
  ref: React.ForwardedRef<{ showRefreshMask: () => void }>
) {
  const { appName } = useStore();
  const { isMobile } = useStore({ keys: ['isMobile'] });
  const [refreshKey, setRefreshKey] = useState(0);
  const [scanned, setScanned] = useState(false);
  const history = useHistory();
  const [showRefreshMask, setShowRefreshMask] = useState(false);
  const [qrCode, setQrCode] = useState('');
  const transferBind = props.from === 'bind' && appName === EMuseApps.museTransfer;
  useLayoutEffect(() => {
    setScanned(false);
    const handleMessage = (msg: any) => {
      if (typeof msg.data === 'object' && !!msg.data.event) {
        switch (msg.data.event) {
          case 'startInit':
            setScanned(true);
            return;
          case 'wxLoginCode':
            const code = msg.data.payload;
            props.onSuccess(code);
            return;
          case 'noCode':
            props.onFail?.();
            setRefreshKey((k) => k + 1);
            return;
        }
      }
    };
    window.addEventListener('message', handleMessage);

    if (__PW__) {
      if (!showRefreshMask) {
        const f = document.createElement('iframe');
        f.src = window.location.origin + '/login/wx-sso?code=code';
        document.getElementById('login_container')!.appendChild(f);
      } else {
        document.getElementById('login_container')!.innerHTML = '';
      }
    } else if (transferBind) {
      serviceGetSSOBindWxQrCode().then((res) => {
        setQrCode(res.url);
      });
    } else {
      window.self_redirect = 1;
      new WxLogin({
        self_redirect: true,
        id: 'login_container',
        appid: 'wx8d1beb4c85ce49f1',
        scope: 'snsapi_login',
        redirect_uri: encodeURIComponent(location.origin + '/login/wx-sso'),
        state: 'STATE',
        style: 'black',
        self_redirect: 1
      });
    }

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [refreshKey, history, props, showRefreshMask]);

  useImperativeHandle(ref, () => {
    return {
      showRefreshMask: () => {
        setShowRefreshMask(true);
      }
    };
  });

  return (
    <div className="wx-qrcode-container">
      <div className={classNames('wx-qrcode-wrapper', props.className)}>
        <div className="wx-true-container">
          {transferBind ? (
            qrCode ? (
              <img className="wx-qrcode-image" src={qrCode} />
            ) : null
          ) : (
            <div key={refreshKey} className="wx-qrcode__container" id="login_container" />
          )}
        </div>
        <div
          className={classNames('wx-qrcode__mask', {
            'wx-qrcode__mask--visible': showRefreshMask
          })}
          onClick={() => {
            setRefreshKey((k) => k + 1);
            setShowRefreshMask(false);
          }}
        >
          <div className="text-[24px] mb-[12px]">
            <RedoOutlined />
          </div>

          <div>
            <Trans>刷新二维码</Trans>
          </div>
        </div>
        {scanned && (
          <div
            className="wx-qrcode__scanned_mask"
            onClick={() => {
              setRefreshKey((k) => k + 1);
            }}
          >
            <div>
              <IconCheckedCircleOutlined size={60} />
            </div>
            <span className="mt-[8px] text">
              <Trans>扫码成功</Trans>
            </span>
          </div>
        )}
      </div>
      <div className="wx-qrcode__hint">
        {transferBind && isMobile && isWechatBrowser() ? (
          <Trans>长按识别二维码</Trans>
        ) : (
          <Trans>请使用微信扫描二维码</Trans>
        )}
      </div>
    </div>
  );
});

export default WxQrCode;
