/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */

import { ErrorSentryIgnore } from './base';

export class ErrorAppResponse extends ErrorSentryIgnore {
  code: number;
  constructor(code: number, message: string) {
    super(message);
    this.code = code;
  }
}

/**
 * code = 100003 or 100004
 */
export class ErrorNotLoggedIn extends ErrorAppResponse {}

/**
 * 403 error
 */
export class Error403 extends ErrorSentryIgnore {}

/**
 * 5xx error
 */
export class ErrorServerSide extends ErrorSentryIgnore {}

