/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */

import { useEffect, useMemo, useState } from 'react';
import { Button, Form, Input, message, Modal as AntModal } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { CloseCircleOutlined, InfoCircleOutlined, MessageOutlined, PhoneOutlined } from '@ant-design/icons';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import classNames from 'classnames';

import { defineStore } from 'shared/hooks/defineStore';
import { setSSOCookies } from 'shared/utils/cookie';
import { showAppModalTipBind } from '../AppModalTipBind/AppModalTipBind';
import $style from './index.module.less';
import { BtnSendVerificationCode } from '@/components-view/AppAuth/LoginFormPhoneWithCode';
import Modal from '@/components/Modal/Modal';
import {
  serviceSendSmsCode,
  serviceBindForce,
  serviceBindPhone,
  serviceChangePhone,
  serviceNewBindPhone
} from '@/services/manage';
import { refetchUser, useStore } from '@/store/app-store';
import { appIdToAppNameInUI } from '@/config/apps-config';
import {
  ErrorBindAlready,
  ErrorBindConflict,
  ErrorNotValidVerifyCode,
  ErrorWrongOriginalPhone,
  ErrorWrongUserNameOrPASSWORD,
  IConflictInfo
} from '@/services/errors';
import { EAppIds } from '@/types';
import useCountdownLoading from '@/hooks/useCountdownLoading';
import { saveXTokenBySSOToken } from '@/utils/login';

export interface IAppModalCommonState {
  visible?: boolean;
  onClose?: () => void;
  successCb?: () => void;
  errorCb?: () => void;
  tokens?: {
    ssoToken: string;
    globalUserId: string;
    userPoolId: string;
  };
}
function Desc() {
  return (
    <div className={`${$style.desc} p2 color-basic-6 mb-[16px]`}>
      <Trans>根据国家相关法律法规，您需要绑定手机号，以完善帐号实名信息，完成注册。</Trans>
    </div>
  );
}
const { hook: useModalState, updateStore: updateModalState } = defineStore({ visible: false } as IAppModalCommonState, {
  name: 'BizPhoneForceModal'
});
export function showAppModal({ successCb, errorCb, tokens }: IAppModalCommonState) {
  updateModalState((st) => ({
    ...st,
    successCb,
    errorCb,
    tokens,
    visible: true
  }));
}

export function closeAppModal() {
  updateModalState((st) => ({
    ...st,
    visible: false
  }));
}
export default function BizPhoneModal() {
  const { visible, onClose, successCb, errorCb, tokens } = useModalState();
  const { isMobile } = useStore({ keys: ['isMobile'] });
  const [form] = useForm();
  const [isDisable, setIsDisable] = useState(true);
  const [isWrongVerifyCode, setIsWrongVerifyCode] = useState(false);
  const [isWrongOriginalPhone, setIsWrongOriginalPhone] = useState(false);
  const [verify, setVerify] = useState('');

  useEffect(() => {
    setIsDisable(!verify);
  }, [verify]);
  async function handleSendSmsCode() {
    const phone = form.getFieldValue('phone');
    const validInfo = verifyPhone(phone);
    if (validInfo?.isEmpty) {
      message.error(<Trans>手机号不能为空</Trans>);
      return -1;
    }

    if (validInfo?.invalid) {
      message.error(<Trans>请输入正确的手机号</Trans>);
      return -1;
    }

    await serviceSendSmsCode('+86', phone, tokens);
    message.success(<Trans>验证码已发送，请查收</Trans>);
  }

  const OriginalPhoneInput = useMemo(() => {
    function handleOriginalPhoneInputChange() {
      if (isWrongOriginalPhone) {
        setIsWrongOriginalPhone(false);
      }
    }
    return (
      <>
        <span className="mt-6 mb-3 text-sm">
          <Trans>原手机号</Trans>
        </span>
        <Form.Item name="originalPhone" noStyle>
          <Input
            className={$style.phone_number_input}
            placeholder={i18n.t('请输入手机号')}
            autoComplete="off"
            prefix={<div className={$style.prefix}>+86</div>}
            onChange={handleOriginalPhoneInputChange}
          />
        </Form.Item>
        <span className={classNames($style.error_tip, { [$style.show]: isWrongOriginalPhone })}>
          <Trans>原手机号错误，请重新输入</Trans>
        </span>
        <div className="mt-3 mb-3 text-sm">
          <Trans>变更的手机号</Trans>
        </div>
      </>
    );
  }, [isWrongOriginalPhone]);

  async function handleConfirm() {
    try {
      const phone = form.getFieldValue('phone');
      const verifyCode = form.getFieldValue('verifyCode');
      const validInfo = verifyPhone(phone);

      if (validInfo?.isEmpty) {
        message.error(<Trans>手机号不能为空</Trans>);
        return;
      }

      if (validInfo?.invalid) {
        message.error(<Trans>请输入正确的手机号</Trans>);
        return;
      }

      if (!verifyCode) {
        message.error(<Trans>验证码不能为空</Trans>);
        return;
      }
      const params = {
        ssoToken: tokens.ssoToken,
        appId: window.$config['APPID'],
        phone: phone,
        captcha: verifyCode,
        countryCode: '+86'
      };
      const res = await serviceNewBindPhone(params);
      if (res) {
        setSSOCookies(res);
      } else {
        setSSOCookies(tokens);
      }
      saveXTokenBySSOToken()
      // await serviceBindPhone({
      //   countryCode: '+86',
      //   phone,
      //   captcha: verifyCode
      // });
      refetchUser();
      closeAppModal();
      message.success(i18n.t('绑定成功'));
      successCb?.();
    } catch (err) {
      if (err instanceof ErrorBindConflict) {
        const conflictData: IConflictInfo = err.data;
        const phone = form.getFieldValue('phone');
        const formattedPhone = `${phone.slice(0, 3)}****${phone.slice(7)}`;
        closeAppModal();
        showAppModalTipBind({
          formattedPhone,
          conflictData,
          okCb: () => {
            serviceBindForce(conflictData.bindCode).then(() => {
              refetchUser();
              message.success(i18n.t('绑定成功'));
              successCb?.();
            });
          },
          errorCb
        });
        return;
      }

      if (err instanceof ErrorBindAlready) {
        message.error(i18n.t('该帐号已绑定，请更换帐号重试'));
        return;
      }

      if (err instanceof ErrorWrongUserNameOrPASSWORD) {
        message.error(i18n.t('用户名或密码错误'));
        return;
      }

      if (err instanceof ErrorWrongOriginalPhone) {
        setIsWrongOriginalPhone(true);
        return;
      }

      if (err instanceof ErrorNotValidVerifyCode) {
        setIsWrongVerifyCode(true);
        return;
      }

      message.error(i18n.t('绑定发生错误，请重新尝试'));
    }
  }

  function verifyPhone(phone: string) {
    if (!phone) {
      return { isEmpty: true };
    }

    if (!/^(1[3-9])\d{9}$/.test(phone)) {
      return { invalid: true };
    }
  }

  function handleVerifyInputChange(e: any) {
    setVerify(e?.target?.value);
    if (isWrongVerifyCode) {
      setIsWrongVerifyCode(false);
    }
  }
  const confrimPhoneIsOk = () => {
    handleConfirm();
  };

  return (
    <Modal
      data-test="forceBindModal-modal1"
      visible={visible}
      title={i18n.t('绑定手机号，完成注册')}
      bodyStyle={{ padding: '24px 24px 0' }}
      showCloseIcon
      centered
      okText={i18n.t('确认')}
      cancelText={i18n.t('取消')}
      onClose={() => {
        errorCb?.();
        closeAppModal();
      }}
      onCancel={() => {
        errorCb?.();
        closeAppModal();
      }}
      className={$style.BizPhoneforceBindModall}
      footer={
        <Button type="primary" data-test="forceBindModal-modal1-btn" disabled={isDisable} onClick={confrimPhoneIsOk}>
          立即绑定
        </Button>
      }
    >
      <div className={$style.modal_content}>
        <Desc />
        <Form form={form} className={$style.phone_form}>
          <Form.Item name="phone" noStyle>
            <Input
              data-test="forceBindModal-phone"
              className={$style.phone_number_input}
              placeholder={i18n.t('请输入手机号')}
              maxLength={11}
              autoComplete="off"
              prefix={<PhoneOutlined />}
            />
          </Form.Item>
          <Form.Item name="verifyCode" noStyle>
            <Input
              data-test="forceBindModal-verifyCode"
              prefix={<MessageOutlined />}
              className="mt-3"
              placeholder={i18n.t('请输入验证码')}
              autoComplete="off"
              suffix={
                <BtnSendVerificationCode style={{ fontSize: '14px', lineHeight: 1 }} onClick={handleSendSmsCode} />
              }
              onChange={handleVerifyInputChange}
            />
          </Form.Item>
          <span className={classNames($style.error_tip, { [$style.show]: isWrongVerifyCode })}>
            <Trans>验证码错误，请重新输入</Trans>
          </span>
        </Form>
      </div>
    </Modal>
  );
}
