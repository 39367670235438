/**
 * tezign ownership
 * @owner chenmingjie
 * @team m2
 */
import React, { FC } from 'react';
import { IconWxLogoSvg } from 'shared/icons';
import Divider from 'antd/lib/divider';
import { Modal } from 'antd';
import style from './index.module.less';
import contactUsPng from '@/assets/contactUs.jpeg';
import wxGroupPng from '@/assets/contactUs.png';

interface IProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const BizContactUs = (props: IProps) => {
  /*  ------------------------------------------react-state------------------------------------------ */
  const { open, setOpen } = props;
  /* ------------------------------------------react-function------------------------------------------ */
  function cancel() {
    setOpen(false);
  }
  /* ------------------------------------------react-effect------------------------------------------ */
  /* ------------------------------------------react-component------------------------------------------ */
  return (
    <Modal onCancel={cancel} visible={open} title="联系我们" width={400} centered footer={null} closable>
      <div className="flex flex-col mb-[20px] ">
        <div className="flex items-center gap-[4px] mb-[16px]">
          <IconWxLogoSvg size={24} className="mr-[6px]" />

          <span className="p1 color-basic-6">根据您的需求，微信扫描下方指定二维码即可</span>
        </div>
        {/* <div className="flex gap-[20px]">
          <img src={wxGroupPng} alt="" className="w-[120px] h-[120px]" />
          <div className="flex flex-col gap-[6px]">
            <span className="color-basic-10 text-[16px] font-semibold">微信群｜用户共创</span>
            <span className="p2 color-basic-6">
              bug反馈、功能建议、产品共创、认识其他创意人、创意资产共享、最新功能资讯、种子用户招募…
            </span>
          </div>
        </div>
        <Divider className="my-[16px]" /> */}
        <div className="flex gap-[20px]">
          <img src={contactUsPng} alt="" className="w-[120px] h-[120px]" />
          <div className="flex flex-col gap-[6px]">
            <span className="color-basic-10 text-[16px] font-semibold">1对1 联系客服</span>
            <span className="p2 color-basic-6">业务咨询、功能咨询、团队服务咨询 商务合作洽谈…</span>
          </div>
        </div>
        <Divider className="my-[16px]" />
        <span className="text-[16px] mb-[20px]">
          <span className="color-basic-5">邮箱:</span>musedam@tezign.com
        </span>
      </div>
    </Modal>
  );
};

export default BizContactUs;
