/**
 * tezign ownership
 * @owner weilingfeng
 * @team muse dam
 */
import React, { useState } from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { message } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  COOKIE_GLOBAL_USER_ID,
  COOKIE_SSO_TOKEN,
  COOKIE_USER_POOL_ID,
  flushUserCookies,
  setSSOCookies
} from 'shared/utils/cookie';
import { trackVisitorRegisterStepLoginSuccess } from 'shared/tracer/register-analysis';
import $style from '@/components-view/AppAuth/AppAuth.module.less';
import { checkLoginStateThenRedirectIfValid, getRedirectUrlFromUrl } from '@/utils/login';
import { useStore } from '@/store/app-store';
import { serviceGetSSOTokenByWxSSO } from '@/services/authentication';
import WxQrCode from '@/components/WxQrCode/WxQrCode';
import { PRIVACY_POLICY, USER_AGREEMENT } from '@/utils/constants';
import { showAppModal } from '@/components-business/BizPhoneForceModal/BizPhoneForceModal';
import { serviceGetUser } from '@/services/manage';

export function FormWxQrCode() {
  // 用于刷新微信二维码
  const [refreshKey, setRefreshKey] = useState(0);
  const { appConfig } = useStore();
  const history = useHistory();
  const url = window.$config['MUSEDAM_LOGIN_REDIRECT_URL'];
  return (
    <div className={$style.wx_qr_code__container}>
      <WxQrCode
        className="mt-[50px]"
        key={refreshKey}
        onSuccess={(code) => {
          const targetUrl = getRedirectUrlFromUrl() || url || window.location.href;
          serviceGetSSOTokenByWxSSO(code)
            .then(async (res) => {
              trackVisitorRegisterStepLoginSuccess();
              // setSSOCookies(res);
              const params = res;
              const user = await serviceGetUser(params);
              if (!user.phone) {
                showAppModal({
                  tokens: params,
                  successCb: () => {
                    checkLoginStateThenRedirectIfValid(targetUrl, history);
                  },
                  errorCb: () => {
                    setRefreshKey((k) => k + 1);
                    // checkLoginStateThenRedirectIfValid(targetUrl, history);
                    flushUserCookies();
                    // message.error(i18n.t('绑定失败，请尝试重新登录'));
                  }
                });
              } else {
                setSSOCookies(res);
                checkLoginStateThenRedirectIfValid(targetUrl, history);
              }
            })
            .catch((err) => {
              setRefreshKey((k) => k + 1);
              flushUserCookies();
              message.error(i18n.t('发生错误，请尝试重新登录'));
              console.error(err);
            });
        }}
        onFail={() => {
          flushUserCookies();
          message.error(i18n.t('发生错误，请尝试重新登录'));
        }}
      />

      <div className={$style.wx_login__subtitle}>
        <Trans>
          扫码表示您已阅读且同意
          <a href={USER_AGREEMENT} className="privacy" target="_blank">
            用户协议
          </a>
          和
          <a className="privacy" href={PRIVACY_POLICY} target="_blank">
            隐私条款
          </a>
        </Trans>
      </div>
    </div>
  );
}
