/**
 * tezign ownership
 * @owner chenmingjie
 * @team m2
 */
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import { Button, Input, message, Tooltip } from 'antd';
import React, { FC, useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined';
import style from './index.module.less';
import i18n from '@/locales/i18n';
import BizContactUs from '@/components-business/BizContactUs/BizContactUs';
import {
  closeAppModal,
  refreshModel,
  updateModalState,
  useModalState
} from '@/components-business/BizSSOScan/BizSSOScan';

interface IProps {
  className?: string;
  okCb?: (val: string) => void;
  cancelCb?: () => void;
  openContactUs?: React.Dispatch<React.SetStateAction<boolean>>;
  scanSuccess?: (code: string, uuid?: string) => void;
}

const AppThirdSSO = (props: IProps) => {
  /*  ------------------------------------------react-state------------------------------------------ */
  const { className, okCb, cancelCb, openContactUs } = props;
  const { inputName } = useModalState();
  const [open, setOpen] = useState(false);
  /* ------------------------------------------react-function------------------------------------------ */

  function handelConfirm() {
    if (!inputName) {
      return message.error('请输入企业标识');
    }
    okCb?.(inputName);
  }
  function setInputName(e: string) {
    updateModalState((st) => ({
      ...st,
      inputName: e
    }));
  }
  /* ------------------------------------------react-effect------------------------------------------ */
  useLayoutEffect(() => {
    const handleMessage = (msg: any) => {
      if (typeof msg.data === 'object' && !!msg.data.event) {
        switch (msg.data.event) {
          case 'ssoLoginCode':
            const { code, uuid } = msg.data.payload;
            props.scanSuccess(code, uuid);
            return;
        }
      }
    };
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [props]);
  /* ------------------------------------------react-component------------------------------------------ */
  return (
    <div className={classNames('flex  flex-col', className)}>
      <div className="flex gap-[6px] mb-[16px] cursor-pointer" onClick={cancelCb}>
        <LeftOutlined className="text-[16px]" />
        <span className="p2">返回</span>
      </div>
      <div className="h7 color-basic-10 mb-[8px]">企业账号登录</div>
      <div className="p2 color-basic-6 mb-[30px]">
        请输入<span className="color-primary">企业标识</span>
        <Tooltip title="MuseDAM 可为企业客户提供 SSO 登录配置服务，您可通过贵团队的企业标识进入。">
          <QuestionCircleOutlined style={{ fontSize: '14px', color: 'rgba(0,0,0,.4)' }} />
        </Tooltip>
        来告知我们你要登录的团队
      </div>
      <Input
        value={inputName}
        onChange={(e) => {
          setInputName(e.target.value);
        }}
        className="mb-[30px] rounded-[8px]"
        placeholder={i18n.t('请输入企业域名')}
        autoComplete="off"
      />
      <Button onClick={handelConfirm} className="mb-[30px]" type="primary" size="large">
        下一步
      </Button>
      <span
        onClick={() => {
          openContactUs(true);
        }}
        className="p2 color-basic-6 text-center cursor-pointer"
      >
        想要定制企业标识？
      </span>
    </div>
  );
};

export default AppThirdSSO;
