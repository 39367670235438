/**
 * tezign ownership
 * @owner
 * @team
 */
import { useEffect, useState } from 'react';

export interface CountDownProps {
  countNum: number;
}
const useCountdownLoading = (props: CountDownProps) => {
  const { countNum } = props;
  const [loading, setloading] = useState(true);
  const [count, setcount] = useState(countNum);
  useEffect(() => {
    setloading(count > 0);
    let timer: number;
    if (count > 0) {
      timer = setTimeout(() => {
        setcount((pre) => pre - 1);
      }, 1000);
    }
    return () => {
      clearTimeout(timer);
      timer = null as any as number;
    };
  }, [count]);
  return { count, loading, setcount };
};

export default useCountdownLoading;
