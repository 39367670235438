/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import './sentry';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'shared/styles/standard.less';
import foundationConfig from '@tezign/foundation-common/lib/utils/config';

import zhCN from 'antd/lib/locale/zh_CN';

import LocaleProvider from 'antd/lib/locale-provider';
import { ConfigProvider } from 'antd';
import antdConfig from 'shared/ant-config';
import { initTracer } from 'shared/tracer/tracer';
import App from './App';
import ErrorBoundary from '@/components-view/ErrorBoundary';
import './locales/i18n';
import { getOrCreateVisitorIdCookie, trackVisitorRegisterStepLogin } from 'shared/tracer/register-analysis';
import { getSSOGlobalUserId } from 'shared/utils/cookie';

try {
  foundationConfig.setConfig(__PROD__ ? 'production' : 'test');
  initTracer({
    production: __PROD__,
    userId: getSSOGlobalUserId() || getOrCreateVisitorIdCookie()
  });

  trackVisitorRegisterStepLogin();
} finally {
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <LocaleProvider locale={zhCN}>
          <ConfigProvider {...antdConfig}>
            <App />
          </ConfigProvider>
        </LocaleProvider>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
  );
}
