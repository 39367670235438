/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */

import { updateCSS } from 'rc-util/lib/Dom/dynamicCSS';

const commonVariables = {
  'danger-1': 'rgba(255, 242, 242, 1)',
  'danger-2': 'rgba(255, 214, 217, 1)',
  'danger-3': 'rgba(255, 168, 180, 1)',
  'danger-4': 'rgba(255, 112, 141, 1)',
  'danger-5': 'rgba(255, 61, 113, 1)',
  'danger-6': 'rgba(219, 44, 102, 1)',
  'danger-7': 'rgba(184, 29, 91, 1)',
  'danger-8': 'rgba(148, 18, 78, 1)',
  'danger-9': 'rgba(112, 9, 64, 1)'
};

type IColorIndex = '1' | '2' | '3' | '4' | '5' | '6' | '7' | '8' | '9';

type PrimaryVars = Record<`primary-${IColorIndex}`, string>;
type BasicVars = Record<`basic-${IColorIndex & '10'}`, string>;

export type IThemeVars = PrimaryVars &
  BasicVars & {
    /**
     * 字体颜色
     */
    'text-color': string;

    'text-default': string;
    'text-color-description': string;

    'tab-text-color': string;
    'tab-active-text-color': string;
    'input-focus-shadow': string;
    primaryColor: string;
    infoColor: string;
    successColor: string;
    warningColor: string;
    errorColor: string;
    processingColor: string;

    [key: string]: any;
  };

const defaultThemeVars: IThemeVars = {
  'box-shadow': '0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%)',
  'background-color': '#fff',
  'modal-mask-background-color': 'rgba(0,0,0,0.45)',
  'basic-0': 'rgba(255, 255, 255, 1)',
  'basic-1': 'rgba(247, 249, 252, 1)',
  'basic-2': 'rgba(237, 241, 247, 1)',
  'basic-3': 'rgba(228, 233, 242, 1)',
  'basic-4': 'rgba(197, 206, 224, 1)',
  'basic-5': 'rgba(143, 155, 179, 1)',
  'basic-6': 'rgba(34, 43, 69, 1)',
  'basic-7': 'rgba(46, 58, 89, 1)',
  'basic-8': 'rgba(25, 32, 56, 1)',
  'basic-9': 'rgba(21, 26, 48, 1)',
  'basic-10': 'rgba(16, 20, 38, 1)',

  'primary-1': '#598BFF',
  'primary-2': '#598BFF',
  'primary-3': '#598BFF',
  'primary-4': '#598BFF',
  'primary-5': '#598BFF',
  'primary-6': '#3366FF',
  'primary-7': '#274BDB',
  'primary-8': '#274BDB',
  'primary-9': '#274BDB',

  'text-color': '#2E3A59',
  'text-default': '#8F9BB3',
  'text-color-description': '#2E3A59',

  'tab-text-color': '#8F9BB3',
  'tab-active-text-color': '#3366FF',
  'input-focus-shadow': 'rgba(51, 102, 255, 0.2)',

  primaryColor: '#3366FF',
  infoColor: 'rgba(0, 149, 255, 1)',
  successColor: 'rgba(0, 214, 143, 1)',
  warningColor: 'rgba(255, 170, 0, 1)',
  errorColor: 'rgba(255, 61, 113, 1)',
  processingColor: '#3366FF'
};

const dynamicStyleMark = `-ant-${Date.now()}-${Math.random()}`;

function updateHtmlThemeColor(color: string) {
  document.querySelector('meta[name="theme-color"]')?.setAttribute('content', color);
}

export function updateCssVars(customThemeVars: Partial<IThemeVars> = {}) {
  const variables = { ...defaultThemeVars, ...customThemeVars };
  // Convert to css variables
  const cssList = Object.keys(variables).map((key) => `--ant-${key}: ${variables[key]};`);

  updateHtmlThemeColor(variables.primaryColor);

  updateCSS(
    `
  :root {
    ${cssList.join('\n')}
  }
  `,
    `${dynamicStyleMark}-dynamic-theme`
  );
}
