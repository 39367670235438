/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */

import { entries } from 'lodash';

export function addParam(url: string, params: Record<string, string | number>): URL {
  const newUrl = new URL(url);
  entries(params).forEach(([key, v]) => {
    newUrl.searchParams.set(key, String(v));
  });

  return newUrl;
}
