/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import { getSSOGlobalUserId, getSSOToken } from 'shared/utils/cookie';
import requestTzUserCenter, { museDamAxios, museDamProfessionalAxios } from '@/services/requestTzUserCenter';
import requestTransfer from '@/services/requestTransfer';
import { readonlyStoreRef } from '@/store/app-store';
import { IAppConfig } from '@/config/apps-config';
import { EAppIds, IUser } from '@/types';
import { MUSE_IDP_ID } from '@/utils/constants';
import { getPlatformType, getContainerType } from '@/utils/getContainerType';

interface IWxQrCodeRes {
  url: string;
  sceneStr: string;
}
interface SSOResult {
  ssoToken: string;
  targetUrl: string;
  code: string;
  appUserId: string;
  userPoolId: string;
  protocol: string;
  idpId: string;
  appId: string;
  globalUserId: string;
}

interface IUserInfoRes {
  ssoToken: string;
  globalUserId: string;
  userPoolId: string;
}

export async function serviceGetThirdSSOToken(
  code: string,
  uuid?: string
): Promise<{
  targetUrl: string;
  idpId: string;
  code: string;
  ssoToken: string;
  globalUserId: string;
  appId: EAppIds;
  userPoolId: string;
}> {
  return (
    await requestTzUserCenter.post('/public/oauth2-sso', {
      code,
      idpId: uuid,
      appId: 'tz_minidam'
    })
  ).data;
}
export async function serviceGetSSOTokenByWxSSO(
  code: string,
  uuid?: string
): Promise<{ ssoToken: string; globalUserId: string; appId: EAppIds; userPoolId: string }> {
  return (
    await requestTzUserCenter.post('/public/oauth2-sso', {
      code,
      idpId: MUSE_IDP_ID,
      appId: 'tz_minidam'
    })
  ).data;
}

export async function serviceGetTzUcCode(): Promise<string> {
  const appId = readonlyStoreRef.value.appId;
  const ssoToken = getSSOToken();
  const globalUserId = getSSOGlobalUserId();
  if (!appId || !ssoToken || !globalUserId) {
    throw new Error(`not valid params: ${appId} ${ssoToken} ${globalUserId} `);
  }

  return (
    await requestTzUserCenter.post('/code/generate', {
      appId,
      globalUserId,
      ssoToken
    })
  ).data;
}

/**
 * 发送手机验证码
 * @param phone 手机号码
 */
export async function serviceSendSmsCode(phone: string) {
  return (
    await requestTzUserCenter.post('/send/sms', {
      appId: readonlyStoreRef.value.appId,
      phone
    })
  ).data;
}
/**
 * 发送企业邮箱验证码
 * @param phone 手机号码
 */
export async function serviceSendOrgEmailCode(email: string) {
  return (
    await requestTzUserCenter.post('/email/captcha', {
      appIds: [readonlyStoreRef.value.appId],
      email
    })
  ).data;
}
/**
 * 验证邮箱是否有效
 * @param phone 手机号码
 */
export async function serviceSendOrgEmailIsValid(email: string, globalUserId: string) {
  return (await museDamAxios.get(`/mini-dam-user/user/universal/email?email=${email}&userId=${globalUserId}`)).data;
}

/**
 * 通过手机号登录
 * @param phone 手机号
 * @param smsCode 验证码
 * @returns 登录成功信息
 */
export async function serviceLoginByPhone(phone: string, smsCode: string): Promise<IUserInfoRes> {
  return (
    await requestTzUserCenter.post('/loginV2', {
      appIds: [readonlyStoreRef.value.appId],
      loginType: 2,
      phone,
      smsCode
    })
  ).data;
}

/**
 * 通过邮箱
 * @param phone 手机号
 * @param smsCode 验证码
 * @returns 登录成功信息
 */
export async function serviceLoginByOrgEmail(email: string, captcha: string): Promise<IUserInfoRes> {
  return (
    await requestTzUserCenter.post('/loginV2', {
      appIds: [readonlyStoreRef.value.appId],
      loginType: 13,
      username: email,
      captcha
    })
  ).data;
}

/**
 * 通过邮箱+密码
 * @param phone 手机号
 * @param smsCode 验证码
 * @returns 登录成功信息
 */
export async function serviceLoginByPhoneOrgEmailWithPassword(
  phoneOrEmail: string,
  pwd: string
): Promise<IUserInfoRes> {
  return (
    await requestTzUserCenter.post('/loginV2', {
      appIds: [readonlyStoreRef.value.appId],
      loginType: 7,
      username: phoneOrEmail,
      pwd
    })
  ).data;
}

/**
 * 获取 orgEmail后缀合法列表
 * @returns 二维码地址 & sceneStr
 */
export async function serviceGetOrgEmailSuffixList(): Promise<IWxQrCodeRes> {
  return (await museDamAxios.get('/mini-dam-material/org/universal/email-domain')).data;
}

/**
 * 获取 transfer 服务号登录微信二维码和 sceneStr
 * @returns 二维码地址 & sceneStr
 */
export async function serviceGetSSOWxQrCode(): Promise<IWxQrCodeRes> {
  return (await requestTransfer.get('/user/public/getSsoWxQrCode')).data;
}

/**
 * 获取 transfer 服务号绑定微信二维码和 sceneStr
 * @returns 二维码地址 & sceneStr
 */
export async function serviceGetSSOBindWxQrCode(): Promise<IWxQrCodeRes> {
  return (await requestTransfer.get('/user/public/getSsoBindWxQrCode')).data;
}

/**
 * 检查 transfer 微信二维码登录状态
 * @param sceneStr
 * @returns 二维码状态 或 登录信息
 */
export async function serviceCheckWxQrCode(sceneStr: string): Promise<-1 | 1 | 2 | IUserInfoRes> {
  return (await requestTransfer.get('/user/public/checkWxQrCode', { params: { sceneStr } })).data;
}

/**
 * 获取所有app配置
 */
export async function serviceGetAppsConfig(): Promise<IAppConfig[]> {
  return (await requestTzUserCenter.get('/configs/muse-apps')).data;
}

// 获取sso登陆列表

export async function serviceGetSSOList({
  appIdList = 'tz_minidam',
  systemType,
  tag
}: {
  platformType?: 'Desktop' | 'Mobile';
  appIdList?: string;
  containerType?: string;
  systemType: string;
  tag: string;
}): Promise<any[]> {
  const platformType = getPlatformType();
  const containerType = getContainerType();
  return (
    await requestTzUserCenter.get(
      `/ssoConfig/tag?appIdList=${appIdList}&platformType=${platformType}&containerType=${containerType}&systemType=${systemType}&tag=${tag}`
    )
  ).data;
}

// 获取sso登陆列表

export async function serviceGetAllSSOList({
  appIdList = 'tz_minidam'
}: {
  platformType?: 'Desktop' | 'Mobile';
  appIdList?: string;
  containerType?: string;
}): Promise<any[]> {
  const platformType = getPlatformType();
  const containerType = getContainerType();
  return (
    await requestTzUserCenter.get(
      `/ssoConfig?appIdList=${appIdList}&platformType=${platformType}&containerType=${containerType}`
    )
  ).data;
}

// 通过点击sso列表登陆

export async function serviceLoginBySSOList(configId: number, appIdList = 'tz_minidam', url?: string): Promise<string> {
  const OUrl = new URL(decodeURIComponent(url || location.href));
  let targetUrl = location.href;
  if (OUrl.searchParams.get('redirect')) {
    const redirectUrl = new URL(OUrl.searchParams.get('redirect'));
    if (location.host === redirectUrl.host) {
      targetUrl = redirectUrl.href;
    } else {
      const originUrl = new URL(window.$config.MUSEDAM_LOGIN_REDIRECT_URL);
      originUrl.search = OUrl.searchParams.toString();
      targetUrl = encodeURIComponent(originUrl.href);
    }
  }
  return (
    await requestTzUserCenter.get(
      `/generateAuthUrl?appIdList=${appIdList}&configId=${configId}${targetUrl ? `&targetUrl=${targetUrl}` : ''}`
    )
  ).data;
}

/**
 * 第三方sso登录成功后生成ssoToken 和 targetUrl
 */
export async function getSSOResult(code: string): Promise<SSOResult> {
  return (await requestTzUserCenter.get(`/getSSOResult?code=${code}`)).data;
}
// 修改用户名和头像
export async function servicePatchNameOrNickname(options: {
  nickName?: string;
  avatarStorePath?: string;
}): Promise<IUser> {
  return (await museDamProfessionalAxios.patch('/mini-dam-user/user', options)).data;
}

let ossIndex = 1;
/**
 * 获取云上传需要的token
 * safari下axios的相同url并行请求会出返回一个相同结果
 * @see https://github.com/axios/axios/issues/3211
 */
export async function getPreUploadToken(): Promise<{ result: any }> {
  return (
    await museDamProfessionalAxios.get(`/mini-dam-material/oss?index=${ossIndex++}`, {
      meta: {
        noProgress: true
      }
    })
  ).data;
}

export async function deregisterAccount(): Promise<{ result: any }> {
  // 注销账户
  return (await requestTzUserCenter.delete(`/profile/accountCancellation`)).data;
}
