/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import { useHistory } from 'react-router-dom';
import { useEffect, useLayoutEffect } from 'react';
import { History } from 'history';
import { useRefMemo } from 'shared/hooks/useRefMemo';
import { EMuseApps } from 'shared/types';
import { ErrorSentryIgnore } from 'shared/errors/base';
import {
  checkLogin,
  flushUserCookies,
  getSSOGlobalUserId,
  getSSOToken,
  getUserPoolId,
  getXtoken,
  hasLogin,
  setSSOCookies,
  setXTokens
} from 'shared/utils/cookie';
import { trackVisitorRegisterStepLoginSuccess } from 'shared/tracer/register-analysis';
import { getAuthInfoByCode } from '@tezign/foundation-common/lib/services/auth';
import tezignConfig from '@tezign/foundation-common/lib/utils/config';

import isAccessibleRoute from '@/routes/routesPermission';
import { goToLoginPage } from '@/utils/navagation';
import { serviceGetTzUcCode } from '@/services/authentication';
import { addParam } from '@/utils/url';
import { readonlyStoreRef } from '@/store/app-store';
import useQuery from '@/hooks/useQuery';
import { serviceGetMuseAiJWT } from '@/services/museai';

enum SSOLoginPlatformType {
  Sop = 'sop', // 大平台业务系统
  Top = 'top', // Top后台专用
  Vms = 'vms' // 数据智能业务系统
}

const platformType = SSOLoginPlatformType.Sop;
export function checkPermission(): boolean {
  if (isAccessibleRoute()) {
    return true;
  }
  return checkLogin();
}

export function useCheckPermissionOnRouteChange(notAllowedCb?: (history: History) => void) {
  const history = useHistory();
  const query = useQuery();
  const cbRef = useRefMemo(notAllowedCb);
  useEffect(() => {
    async function check() {
      // 支持微信公众号绑定登录回调h5场景
      const state = query.get('state');
      if (state?.startsWith('bind-')) {
        console.log(state);
        const userInfos = state.split('-');
        setSSOCookies({ globalUserId: userInfos[1], ssoToken: userInfos[3], userPoolId: userInfos[2] });
        await saveXTokenBySSOToken();
      }
      const allow = checkPermission();
      const cb = cbRef.current;
      if (!allow) {
        cb ? cb(history) : goToLoginPage(history);
      }
    }

    check();

    history.listen(() => {
      check();
    });
  }, [cbRef, history]);
}

export async function saveXTokenBySSOToken(): Promise<void> {
  const code = await serviceGetTzUcCode();
  const res = await getAuthInfoByCode(code);
  setXTokens({ xToken: res.token, globalUserId: res.globalUserId });
}
/**
 * 检查登录态，获取tz_uc_code, 并重定向至目标地址
 */
export async function checkLoginStateThenRedirectIfValid(redirectUrl?: string, history?: History) {
  async function getDefaultRedirectUrl(): Promise<string> {
    const searchParams = new URL(window.location.href).searchParams;
    const redirectUrl = searchParams.get('redirect') || '';
    if (redirectUrl) {
      return redirectUrl;
    }
    // const host = location.host;
    // const urls = JSON.parse(window.$config['MUSEDAM_DOMAIN_URLS']);
    const url = new URL(window.$config['MUSEDAM_LOGIN_REDIRECT_URL']);
    const ssoTag = searchParams.get('ssoTag');
    if (ssoTag) {
      url.searchParams.append('ssoTag', ssoTag);
    }
    return url.href;
  }
  if (!hasLogin()) {
    flushUserCookies();
    throw new ErrorSentryIgnore('not login');
  } else {
    const urlStr = await getDefaultRedirectUrl();
    const url = new URL(urlStr);
    // if (window.location.origin === url.origin) {
    //   if (!history) {
    //     throw new Error('站内跳转要使用history.push，未传history');
    //   }
    //   history.replace(url.pathname + url.search);
    //   return;
    // }
    const type = new URL(window.location.href).searchParams.get('type');
    if (type && type === 'jwt' && getXtoken()) {
      console.log('@@@ >>>>>>>>>>> jwt', getXtoken());
      tezignConfig.setConfig(__DEV__ ? 'test' : 'production');
      const globalUserId = getSSOGlobalUserId();
      const xToken = getXtoken();
      const userPoolId = getUserPoolId();
      const SSOToken = getSSOToken();
      const { token: jwt } = await serviceGetMuseAiJWT(xToken, globalUserId);
      url.searchParams.append('jwt', jwt);
      url.searchParams.append('musedam_SSOToken', SSOToken);
      url.searchParams.append('musedam_xToken', xToken);
      url.searchParams.append('musedam_globalUserId', globalUserId);
      url.searchParams.append('musedam_userPoolId', userPoolId);

      window.location.replace(url.href);
    } else if (readonlyStoreRef.value.appConfig?.disableNavigateWithTzUcCode) {
      window.location.replace(url.href);
    } else {
      if (!getXtoken()) {
        // 如果没有xtoken那么久主动获取并设置
        const code = await serviceGetTzUcCode();
        const res = await getAuthInfoByCode(code);
        setXTokens({ xToken: res.token, globalUserId: res.globalUserId });
        window.location.replace(addParam(url.href, { tz_uc_code: code }).href);
      } else {
        window.location.replace(url.href);
      }
    }
  }
}

export function getRedirectUrlFromUrl() {
  return new URL(window.location.href).searchParams.get('redirect') || '';
}
