/**
 * tezign ownership
 * @owner
 * @team
 */
import 'dingtalk-jsapi/entry/union';
import dingTalkOpenLink from 'dingtalk-jsapi/api/biz/util/openLink';
import dingTalkClosePage from 'dingtalk-jsapi/api/biz/navigation/close';
import { getAuthInfoByCode } from '@tezign/foundation-common/lib/services/auth';
import { setXTokens } from 'shared/utils/cookie';
import { getEndType, addParamsToUrl, EndTypeEnum, getURLParameters } from './getContainerType';
import { serviceGetMuseAiJWT } from '@/services/museai';

export enum LOGIN_JUMP_TYPE {
  ORIGIN_WINDOW = 0,
  OPEN_NEW_WINDOW = 1
}
interface Params {
  appUrl: string;
  authCode: string;
  loginJumpType?: LOGIN_JUMP_TYPE;
  queryObj?: { [key: string]: string };
}
export default async function handleRedirectToApp({ appUrl, authCode, loginJumpType, queryObj = {} }: Params) {
  const url = new URL(decodeURIComponent(appUrl));
  const { origin, pathname, hash, search } = url;
  // external_open_in 参数用来指示在哪个应用容器内使用外部浏览器打开业务系统的url
  // 此参数只供用户中心内部使用，不用带到业务系统
  const query = getURLParameters(search);
  // external_open_in 参数用来指示在哪个应用容器内使用外部浏览器打开业务系统的url
  // 此参数只供用户中心内部使用，不用带到业务系统
  const href = addParamsToUrl(`${origin}${pathname}${hash}`, {
    ...query,
    ...queryObj,
    tz_uc_code: authCode
  });
  // 新窗口打开
  if (loginJumpType === LOGIN_JUMP_TYPE.OPEN_NEW_WINDOW) {
    window.open(href);
    return;
  }

  // 钉钉内调用外部浏览器打开
  if (getEndType() === EndTypeEnum.DINGTALK_CONTAINER) {
    dingTalkOpenLink({
      url: href
    }).then(dingTalkClosePage);
    return;
  }
  // jwt museai 登录
  const type = url.searchParams.get('type');
  const result = await getAuthInfoByCode(authCode);
  setXTokens({ xToken: result.token, globalUserId: result.globalUserId });
  if (type && type === 'jwt') {
    const { token, globalUserId } = result;
    const { token: jwt } = await serviceGetMuseAiJWT(token, globalUserId);
    url.searchParams.delete('type');
    url.searchParams.append('jwt', jwt);
    window.location.replace(url.href);
    return;
  }

  window.location.replace(href);
}
