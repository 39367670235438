/**
 * tezign ownership
 * @owner chenmingjie
 * @team m2
 */
import { useRequest } from 'ahooks';
import React, { FC } from 'react';
import classNames from 'classnames';
import { Divider } from 'antd';
import { Trans } from 'react-i18next';
import $style from './index.module.less';
import feishuSvg from '@/assets/feishu.svg';
import dingdingSvg from '@/assets/dingding.svg';
import qiweiSvg from '@/assets/qiwei.svg';

import { ELoginType } from '@/components-view/AppAuth/AppAuthLogin';

interface IProps {
  okCb?: (id: ELoginType) => void;
}

const BizSsoListLogin = (props: IProps) => {
  /*  ------------------------------------------react-state------------------------------------------ */
  const { okCb } = props;
  const iconList = [
    {
      icon: feishuSvg,
      name: '飞书',
      id: ELoginType.feishu
    },
    {
      icon: dingdingSvg,
      name: '钉钉',
      id: ELoginType.dingding
    },
    {
      icon: qiweiSvg,
      name: '企业微信',
      id: ELoginType.qiwei
    }
  ];
  //   const { data, error, loading } = useRequest(async () => {
  //     const result = await serviceGetSSOList();
  //     return result
  //       .filter((ele) => ele.systemType !== 'tezignWeixin' && ele.systemType !== 'common')
  //       .map((ele) => ({ ...ele, dataInfo: iconList[ele.systemType] }));
  //   });
  /* ------------------------------------------react-function------------------------------------------ */
  async function openSsoLogin(id: ELoginType) {
    okCb?.(id);
    // const result2 = await serviceLoginBySSOList(id);
    // location.href = result2;
  }
  /* ------------------------------------------react-effect------------------------------------------ */
  /* ------------------------------------------react-component------------------------------------------ */
  return (
    <div className="w-full">
      <Divider dashed type="horizontal" plain>
        <Trans>已开通 SSO 企业</Trans>
      </Divider>
      <div className="flex justify-center gap-[30px]">
        {iconList?.map((ele) => (
          <div
            onClick={() => {
              openSsoLogin(ele.id);
            }}
            key={ele.id}
            className={classNames('flex flex-col gap-[8px] items-center cursor-pointer', $style.ssoLogos)}
          >
            <div className=" border border-[#E4E9F2] transition-all duration-200 hover:border-[#C5CEE0] border-solid rounded-full flex justify-center items-center w-[40px] h-[40px]">
              <img src={ele.icon} className={classNames('w-[26px] h-[26px]')} />
            </div>
            <span className="color-basic-5">{ele.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BizSsoListLogin;
