/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */

export interface IUser {
  nickname: string;
  username: string;
  avatarUrl: string;
  phone: string;
  email?: string | null;
  national: number;
  id: string;
  appAuthorizationInfo: { appId: string }[];
  idpUserInfoList: {
    idpId: string;
    username: string;
    avatarUrl: string;
  }[];
  pwd?: string;
  pwdMd5Argon2?: string;
  systemType?: string | null;
}

export interface IFooterLogo {
  appId: string;
  url: string;
  tip: string;
  href: string;
}

export enum EAppIds {
  museTransfer = 'tz_muse_transfer',
  museDam = 'tz_minidam'
}

export enum ELoginMethods {
  phoneOrEmailWithCode = 'PHONE_OR_EMAIL_WITH_CODE',
  password = 'PASSWORD',
  wxQrCode = 'WX_QR_CODE',
  phoneWithCode = 'PHONE_WITH_CODE',
  wxServiceAccountQrCode = 'WX_SERVICE_ACCOUNT_QR_CODE',
  orgEmailCode = 'ORG_EMAIL_CODE'
}
