/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import Cookies from 'js-cookie';

export function hasLoginBefore() {
  return !!getSSOGlobalUserId();
}
export const COOKIE_X_TOKEN = 'xtoken';
export const COOKIE_X_GLOBAL_USER_ID = 'globalUserId';

export const COOKIE_SSO_TOKEN = 'sso_token';
export const COOKIE_GLOBAL_USER_ID = 'guid';
export const COOKIE_USER_POOL_ID = 'user_pool_id';
export const ssoCookieOptions = {
  expires: 30
};
export function setSSOCookies(data: { globalUserId: string; ssoToken: string; userPoolId: string }) {
  Cookies.set(COOKIE_GLOBAL_USER_ID, data.globalUserId, ssoCookieOptions);
  Cookies.set(COOKIE_SSO_TOKEN, data.ssoToken, ssoCookieOptions);
  Cookies.set(COOKIE_USER_POOL_ID, data.userPoolId, ssoCookieOptions);
  localStorage.setItem(COOKIE_GLOBAL_USER_ID, data.globalUserId);
  localStorage.setItem(COOKIE_SSO_TOKEN, data.ssoToken);
  localStorage.setItem(COOKIE_USER_POOL_ID, data.userPoolId);
}

export function setXTokens(data: { xToken: string; globalUserId: string }) {
  Cookies.set(COOKIE_X_TOKEN, data.xToken, ssoCookieOptions);
  localStorage.setItem(COOKIE_X_TOKEN, data.xToken);
  Cookies.set(COOKIE_X_GLOBAL_USER_ID, data.globalUserId, ssoCookieOptions);
  localStorage.setItem(COOKIE_X_GLOBAL_USER_ID, data.globalUserId);
}

export function getSSOGlobalUserId(): string | undefined {
  return Cookies.get(COOKIE_GLOBAL_USER_ID) || localStorage.getItem(COOKIE_GLOBAL_USER_ID) || '';
}

export function getSSOToken(): string | undefined {
  return Cookies.get(COOKIE_SSO_TOKEN) || localStorage.getItem(COOKIE_SSO_TOKEN) || '';
}

export function getUserPoolId(): string | undefined {
  return Cookies.get(COOKIE_USER_POOL_ID) || localStorage.getItem(COOKIE_USER_POOL_ID) || '';
}

export function getXtoken(): string | undefined {
  return Cookies.get(COOKIE_X_TOKEN) || localStorage.getItem(COOKIE_X_TOKEN) || '';
}
export function hasLogin() {
  return !!getSSOToken() && !!getSSOGlobalUserId() && !!getUserPoolId();
}

export function checkLogin() {
  const isLoggedIn = hasLogin();
  if (isLoggedIn) return true;
  return false;
}

export function getTokens() {
  const userId = getSSOGlobalUserId();
  const token = getSSOToken();
  const userPoolId = getUserPoolId();
  if (!userId || !token) {
    return null;
  }
  return {
    userId,
    token,
    userPoolId
  };
}

export function flushUserCookies() {
  Cookies.remove(COOKIE_SSO_TOKEN, ssoCookieOptions);
  Cookies.remove(COOKIE_GLOBAL_USER_ID, ssoCookieOptions);
  Cookies.remove(COOKIE_USER_POOL_ID, ssoCookieOptions);
  localStorage.removeItem(COOKIE_GLOBAL_USER_ID);
  localStorage.removeItem(COOKIE_SSO_TOKEN);
  localStorage.removeItem(COOKIE_USER_POOL_ID);
  localStorage.removeItem(COOKIE_SSO_TOKEN);
  localStorage.removeItem(COOKIE_GLOBAL_USER_ID);
  Cookies.remove(COOKIE_SSO_TOKEN, { domain: '.musedam.cc' });
  Cookies.remove(COOKIE_GLOBAL_USER_ID, { domain: '.musedam.cc' });
  Cookies.remove(COOKIE_USER_POOL_ID, { domain: '.musedam.cc' });
}
