/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import Cookies from 'js-cookie';
import { hasLoginBefore } from '../utils/cookie';
import tracer, { _tracer } from './tracer';

export enum EStep {
  public = 'public',
  login = 'login',
  loginSuccess = 'login_success'
}

const ssoCookieOptions = {
  domain: '.musetransfer.com',
  expires: 365
};

const COOKIE_KEY_ROOT_VISITOR_FROM = 'rootVisitorFrom';
const FROM_KEY = 'visitFrom';
const COOKIE_KEY_ROOT_VISITOR = 'rootVisitorId';
const STORAGE_VISITOR_KEY = 'tz_tracer_visitor';

function setCookieToRootDomain(key: string, value: string) {
  Cookies.set(key, value, ssoCookieOptions);
}

export function setCookieVisitorFrom(from: string) {
  setCookieToRootDomain(COOKIE_KEY_ROOT_VISITOR_FROM, from);
}

export function getVisitorIdFromRootCookie() {
  return Cookies.get(COOKIE_KEY_ROOT_VISITOR);
}

export function getOrCreateVisitorIdCookie(): string | undefined {
  // 取visitorId 然后覆盖
  let visitorId: string | null | undefined = getVisitorIdFromRootCookie();
  if (!visitorId) {
    visitorId = JSON.parse(localStorage.getItem(STORAGE_VISITOR_KEY) || '""');
    visitorId && setCookieToRootDomain(COOKIE_KEY_ROOT_VISITOR, visitorId);
  }
  visitorId && localStorage.setItem(STORAGE_VISITOR_KEY, JSON.stringify(visitorId));
  return visitorId || undefined;
}

export function getCookieVisitorFrom() {
  return Cookies.get(COOKIE_KEY_ROOT_VISITOR_FROM);
}

/**
 * 从url获取来源并设置cookie到根域名，然后删除该参数
 */
export function setCookieVisitorByUrl() {
  const url = new URL(document.location.href);
  if (!hasLoginBefore()) {
    const from = url.searchParams.get(FROM_KEY) || document.referrer;
    from && setCookieVisitorFrom(from || '');
  }
  url.searchParams.delete(FROM_KEY);
  window.history.replaceState({}, document.title, url.href);
}

export function trackVisitorRegister(step: EStep) {
  setCookieVisitorByUrl();
  getOrCreateVisitorIdCookie();
  if (hasLoginBefore()) return;
  const from = getCookieVisitorFrom();
  if (from) {
    _tracer.track({
      event_type_code: 'Minidam_Visitor_Register_Step',
      event_properties: {
        visit_from: from,
        register_step: step
      }
    });
  }
}

export const trackVisitorRegisterStepLogin = () => trackVisitorRegister(EStep.login);
export const trackVisitorRegisterStepLoginSuccess = () => trackVisitorRegister(EStep.loginSuccess);
