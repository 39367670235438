/**
 * tezign ownership
 * @owner weilingfeng
 * @team M1
 */
import React from 'react';
import * as Sentry from '@sentry/react';
import { ErrorBoundaryProps } from '@sentry/react/dist/errorboundary';

export default function ErrorBoundary(
  props: React.PropsWithChildren<ErrorBoundaryProps & { fallback?: React.ReactNode }>
) {
  return (
    <Sentry.ErrorBoundary
      {...props}
      fallback={props.fallback || <div className="vh100 flex-center">抱歉，出错了。。。</div>}
    >
      {props.children}
    </Sentry.ErrorBoundary>
  );
}
