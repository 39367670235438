/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import { getSSOGlobalUserId, getSSOToken, getUserPoolId } from 'shared/utils/cookie';
import { IUser } from '../types';
import requestTzUserCenter from './requestTzUserCenter';
import { MUSE_IDP_ID } from '@/utils/constants';

interface IBindPhoneReq {
  phone: string;
  captcha: string;
  countryCode: string;
}

interface IChangePhoneReq {
  phone: string;
  captcha: string;
  countryCode: string;
  originalCountryCode: string;
  originalPhone: string;
}

export async function serviceGetUser(params?: {
  ssoToken: string;
  globalUserId: string;
  userPoolId: string;
}): Promise<IUser> {
  return (
    await requestTzUserCenter.get(
      `/profile/user?appId=tz_minidam${
        params
          ? '&X-SSO-Token=' +
            params.ssoToken +
            '&X-Global-User-Id=' +
            params.globalUserId +
            '&X-User-POOL-Id=' +
            params.userPoolId
          : ''
      }`
    )
  ).data;
}

/**
 * 发送手机验证码 - 绑定页用
 * @param phone 手机号码
 */
export async function serviceSendSmsCode(
  countryCode: string,
  phone: string,
  tokens?: {
    ssoToken: string;
    globalUserId: string;
    userPoolId: string;
  }
) {
  return (
    await requestTzUserCenter.post(
      `/profile/verify-code${
        tokens
          ? '?X-SSO-Token=' +
            tokens.ssoToken +
            '&X-Global-User-Id=' +
            tokens.globalUserId +
            '&X-User-POOL-Id=' +
            tokens.userPoolId
          : ''
      }`,
      {
        countryCode,
        phone
      }
    )
  ).data;
}

/**
 * 绑定微信
 * @param code 微信回调 code
 * @param wxAppId 微信 app id
 * @returns 绑定信息
 */
export async function serviceBindWx(code: string, wxAppId?: string): Promise<void> {
  return (
    await requestTzUserCenter.post('/profile/oauth2-bind-third-user', {
      idpId: MUSE_IDP_ID,
      code: code,
      wxAppId
    })
  ).data;
}

/**
 * 绑定冲突的账号
 * @param bindCode 绑定 code
 * @returns
 */
export async function serviceBindForce(bindCode: string): Promise<void> {
  return (
    await requestTzUserCenter.post('/profile/force-bind', {
      bindCode
    })
  ).data;
}

/**
 * 解绑微信
 * @returns
 */
export async function serviceUnbindWx(): Promise<void> {
  return (
    await requestTzUserCenter.post('/profile/unbind-third-user', {
      idpId: MUSE_IDP_ID
    })
  ).data;
}

/**
 * 绑定手机号
 */
export async function serviceBindPhone(data: IBindPhoneReq): Promise<void> {
  return (await requestTzUserCenter.post('/profile/bind-phone', data)).data;
}

/**
 * 更换手机号
 */
export async function serviceChangePhone(data: IChangePhoneReq): Promise<void> {
  return (await requestTzUserCenter.post('/profile/change-phone', data)).data;
}

/**
 * 新手机号绑定接口，如果微信扫码后 手机号绑定的时候手机号下的账户没有绑定微信就绑定这个微信
 */
export async function serviceNewBindPhone(params: {
  ssoToken: string;
  appId: string;
  phone: string;
  captcha: string;
  countryCode: string;
}): Promise<void> {
  return (await requestTzUserCenter.post(`/profile/initBindPhone`, params)).data;
}

/**
 * 设置/修改密码
 */
export async function serviceSetUpUserPassword({ pwd, checkPwd }: { pwd: string; checkPwd: string }) {
  return (
    await requestTzUserCenter.put('/profile/set-up-password', {
      pwd,
      checkPwd
    })
  ).data;
}
