/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */

import { Carousel } from 'antd';

import $style from './AppAuthLoginCarousel.module.less';
import { readonlyStoreRef } from '@/store/app-store';

export default function AppAuthLoginCarousel() {
  const { carouselMaterials = [] } = readonlyStoreRef.value.appConfig || {};
  const autoplay = carouselMaterials.every(item => item.type === 'image');

  return (
    <div className={$style.login_carousel}>
      <Carousel autoplay={autoplay}>
        {carouselMaterials.map((item, index) => {
          if (item.type === 'image') {
            return <img className={$style.item} key={index} src={item.url} />;
          }
          return (
            <div className={$style.video_wrapper} key={index}>
              <video className={$style.item} autoPlay loop muted src={item.url} />
            </div>
          );
        })}
      </Carousel>
    </div>
  );
}
