/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import TezignTracer from 'tezign-tracer-sdk';
import type WebTracer from 'tezign-tracer-sdk/es/platforms/web';
import { useEffect } from 'react';
import { History } from 'history';
import { ITrack } from 'tezign-tracer-sdk/es/type';

export const _tracer: InstanceType<typeof WebTracer> = new TezignTracer() as any;

const tracer: Readonly<{ track: WebTracer['track'] }> = {
  track(data: ITrack) {
    _tracer.track({
      ...data,
      event_properties: {
        ...data.event_properties,
        url: window.location.href
      }
    });
  }
};

interface InitTracerOptions {
  userId?: string;
  production: boolean;
}

export function initTracer(options: InitTracerOptions) {
  const globalUserId = options.userId;

  _tracer.init({
    env: options.production ? 'production' : 'development',
    global_user_id: globalUserId,
    tenant_id: 'minidam',
    user_id: globalUserId
  });
}

export function useInitTracer(config: InitTracerOptions & { history?: History }) {
  const { history } = config;

  useEffect(() => {
    initTracer({ userId: config.userId, production: config.production });

    function visit() {
      tracer.track({
        event_type_code: 'Page_View',
        event_properties: {
          path: window.location.pathname,
          host: window.location.host
        }
      });
    }

    visit();

    history?.listen(() => {
      visit();
    });
  }, [history, config.userId, config.production]);
}

export default tracer;
