/**
 * tezign ownership
 * @owner chenqijin
 * @team M3
 */
import React from 'react';

export function useRefMemo<T>(value: T) {
  const ref = React.useRef(value);
  ref.current = value;

  return ref;
}
