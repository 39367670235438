/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import { ErrorAppResponse } from 'shared/errors/http';

export * from 'shared/errors/http';

/**
 * 10 验证码错误
 */
export class ErrorNotValidVerifyCode extends ErrorAppResponse {
  constructor(message: string) {
    super(10, message);
  }
}

/**
 * 108009 用户名或密码错误
 */
export class ErrorWrongUserNameOrPASSWORD extends ErrorAppResponse {
  constructor(message: string) {
    super(108009, message);
  }
}

/**
 * 108010 用户名或密码错误
 */
export class ErrorWrongUserNameOrPASSWORD2 extends ErrorAppResponse {
  constructor(message: string) {
    super(108010, message);
  }
}

/**
 * 120001 绑定冲突
 */
export class ErrorBindAlready extends ErrorAppResponse {
  constructor(message: string) {
    super(120001, message);
  }
}

export interface IConflictInfo {
  conflictInfo: {
    globalUserId: string;
    username: string;
    appAuthorizationInfo: { appId: string; appName: string }[];
  };
  bindCode: string;
}
/**
 * 120002 绑定冲突
 */
export class ErrorBindConflict extends ErrorAppResponse {
  public data: IConflictInfo;
  constructor(message: string, data: IConflictInfo) {
    super(120002, message);
    this.data = data;
  }
}

/**
 * 50001 未登录
 */
export class ErrorNotLogin extends ErrorAppResponse {
  constructor(message: string) {
    super(50001, message);
  }
}

/**
 * 120003 更换手机号，原手机号不匹配
 */
export class ErrorWrongOriginalPhone extends ErrorAppResponse {
  constructor(message: string) {
    super(120003, message);
  }
}

export const Response_Errors: Record<number, new (...args: any) => ErrorAppResponse> = {
  10: ErrorNotValidVerifyCode,
  50001: ErrorNotLogin,
  108009: ErrorWrongUserNameOrPASSWORD,
  108010: ErrorWrongUserNameOrPASSWORD2,
  120001: ErrorBindAlready,
  120002: ErrorBindConflict,
  120003: ErrorWrongOriginalPhone
};
