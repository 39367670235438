/**
 * tezign ownership
 * @owner weilingfeng, yangliangfei
 * @team Muse
 */
import { useForm } from 'antd/es/form/Form';
import { Button, ButtonProps, Form, Input, message, Radio } from 'antd';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { flushUserCookies, setSSOCookies } from 'shared/utils/cookie';
import { trackVisitorRegisterStepLoginSuccess } from 'shared/tracer/register-analysis';
import { useRequest } from 'ahooks';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import $style from '@/components-view/AppAuth/AppAuth.module.less';
import { checkLoginStateThenRedirectIfValid, saveXTokenBySSOToken } from '@/utils/login';
import {
  serviceGetOrgEmailSuffixList,
  serviceLoginByPhoneOrgEmailWithPassword,
  serviceSendOrgEmailIsValid
} from '@/services/authentication';
import {
  ErrorNotValidVerifyCode,
  ErrorWrongUserNameOrPASSWORD,
  ErrorWrongUserNameOrPASSWORD2
} from '@/services/errors';
import { PRIVACY_POLICY, USER_AGREEMENT } from '@/utils/constants';
import { changeShakeRadio, toggleCheck, useStore } from '@/store/app-store';
import { validateEmail } from '@/utils/validators';
import { ELoginMethods } from '@/types';

export function LoginFormPhoneOrEmailWithPassword({ onSwitchTab }: { onSwitchTab: (key: ELoginMethods) => void }) {
  const {
    data: suffixList = [],
    error,
    loading
  } = useRequest(serviceGetOrgEmailSuffixList, {
    retryCount: 3
  });

  const [form] = useForm();
  const { isCheck: radioChecked, isShakeRadio: shakeRadio } = useStore();
  const history = useHistory();

  async function handleLoginByPassword() {
    try {
      const phoneOrEmail = form.getFieldValue('phoneOrEmail');
      const password = form.getFieldValue('password');

      if (!radioChecked) {
        changeShakeRadio(true);

        return;
      }

      if (!phoneOrEmail) {
        message.error(<Trans>手机号/企业邮箱不能为空</Trans>);
        return;
      }
      if (!password) {
        message.error(<Trans>密码不能为空</Trans>);
        return;
      }

      const isValidPhone = /^(1[3-9])\d{9}$/.test(phoneOrEmail);
      const isValidEmail = validateEmail(phoneOrEmail);
      if (isValidEmail) {
        if (!suffixList.some((ele) => !!ele && phoneOrEmail.search(ele) !== -1)) {
          message.error(<Trans>该企业邮箱暂无权限，仅支持绑定了团队的用户</Trans>);
          return;
        }
        const res = await serviceLoginByPhoneOrgEmailWithPassword(phoneOrEmail, password);
        if (!res || !res.globalUserId || !res.ssoToken) {
          message.error(<Trans>发生错误，请尝试重新登录</Trans>);
          return;
        }
        const isValid = await serviceSendOrgEmailIsValid(phoneOrEmail, res.globalUserId);
        if (!isValid) {
          message.error(<Trans>该企业邮箱暂无权限，仅支持绑定了团队的用户</Trans>);
          // 不合法则不允许
          return;
        }
        trackVisitorRegisterStepLoginSuccess();

        setSSOCookies({ globalUserId: res.globalUserId, ssoToken: res.ssoToken, userPoolId: res.userPoolId });
        await saveXTokenBySSOToken();
        checkLoginStateThenRedirectIfValid('', history).catch((err) => {
          message.error(<Trans>发生错误，请尝试重新登录</Trans>);
          console.error(err);
        });
      } else if (isValidPhone) {
        const res = await serviceLoginByPhoneOrgEmailWithPassword(phoneOrEmail, password);
        if (!res || !res.globalUserId || !res.ssoToken) {
          message.error(<Trans>发生错误，请尝试重新登录</Trans>);
          return;
        }
        trackVisitorRegisterStepLoginSuccess();

        setSSOCookies({ globalUserId: res.globalUserId, ssoToken: res.ssoToken, userPoolId: res.userPoolId });
        await saveXTokenBySSOToken();
        checkLoginStateThenRedirectIfValid('', history).catch((err) => {
          message.error(<Trans>发生错误，请尝试重新登录</Trans>);
          console.error(err);
        });
      } else {
        message.error(<Trans>请输入正确的手机号或企业邮箱</Trans>);
        return;
      }
    } catch (err: any) {
      if (err instanceof ErrorNotValidVerifyCode) {
        message.error(i18n.t('验证码不正确'));
        return;
      }

      if (err instanceof ErrorWrongUserNameOrPASSWORD || err instanceof ErrorWrongUserNameOrPASSWORD2) {
        message.error(i18n.t('用户名或密码错误'));
        return;
      }
      flushUserCookies();
      message.error(i18n.t('发生错误，请尝试重新登录'));
    }
  }

  function handleRadioClick() {
    toggleCheck();
  }

  function handleAnimationEnd() {
    changeShakeRadio(false);
  }

  return (
    <div>
      <Form form={form} className={$style.phone_password_login_form}>
        <Form.Item name="phoneOrEmail" noStyle>
          <Input
            className="mt-[24px]"
            suffix={<div className={classNames($style.phone_input__suffix, 'hidden')} />}
            placeholder={i18n.t('请输入手机号/企业邮箱')}
            autoComplete="off"
          />
        </Form.Item>
        <Form.Item name="password" noStyle>
          <Input.Password placeholder={i18n.t('请输入密码')} className={classNames('mt-[16px]')} autoComplete="off" />
        </Form.Item>
        <div
          className={$style.login_description_underline}
          onClick={() => onSwitchTab(ELoginMethods.phoneOrEmailWithCode)}
        >
          <Trans>新用户？请使用验证码登录/注册</Trans>
        </div>
        <Button htmlType="submit" className={$style.submit_btn} type="primary" onClick={handleLoginByPassword}>
          <Trans>登录/注册</Trans>
        </Button>
        <div
          className={classNames($style.login_agreement, { [$style.shake]: shakeRadio })}
          onAnimationEnd={handleAnimationEnd}
        >
          <Radio checked={radioChecked} onClick={handleRadioClick} />
          <Trans>
            我已阅读且同意
            <a href={USER_AGREEMENT} className="privacy" target="_blank">
              用户协议
            </a>
            和
            <a className="privacy" href={PRIVACY_POLICY} target="_blank">
              隐私条款
            </a>
          </Trans>
        </div>
      </Form>
    </div>
  );
}
