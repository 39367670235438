/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import { History } from 'history';
import { EMuseAppPaths, EMuseApps, museAppPathToName } from 'shared/types';
import { readonlyStoreRef } from '@/store/app-store';

export function goToLoginPage(history: History, params: { redirect?: string } = {}) {
  function getDefaultRedirectUrl() {
    return new URL(window.location.href).searchParams.get('redirect') || '';
  }

  const { redirect = getDefaultRedirectUrl() } = params;

  history.replace({
    pathname: '/login',
    search: redirect ? `?redirect=${encodeURIComponent(redirect)}` : ''
  });
}
