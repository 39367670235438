/**
 * tezign ownership
 * @owner weilingfeng
 * @team M1
 */
import React from 'react';
import { Spin } from 'antd';

export default function SpinLoading() {
  return (
    <div className="flex-center vh100">
      <Spin spinning />
    </div>
  );
}
