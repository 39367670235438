/**
 * tezign ownership
 * @owner
 * @team
 */
import { museAiAxios } from './requestTzUserCenter';

export async function serviceGetMuseAiJWT(token: string, userId: string): Promise<{ token: string }> {
  return (await museAiAxios.post('/auth/jwt', { token, userId })).data;
}
