/**
 * tezign ownership
 * @owner weilingfeng
 * @team M3
 */
import { Modal as AntModal, ModalProps } from 'antd';

import './Modal.less';
import classNames from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ICON_CLOSE from '@/assets/icon-close.svg';

interface IProps {
  title: React.ReactNode;
  showCloseIcon?: boolean;
  onClose?: () => void;
  type?: 'warn' | 'error';
}

export default function Modal(props: React.PropsWithChildren<IProps> & ModalProps) {
  const { type, title, children, showCloseIcon, onClose, ...rest } = props;

  function getHeader() {
    return (
      <div className={classNames('muse-modal__header justify-between flex')}>
        <span className="muse-modal__header__title">
          {type === 'warn' && (
            <span className="mr-[16px]" style={{ color: '#FFAA00', fontSize: 20 }}>
              <ExclamationCircleOutlined />
            </span>
          )}
          <span>{title}</span>
        </span>
        {showCloseIcon && <img className="muse-modal__header__icon-close" src={ICON_CLOSE} onClick={onClose} />}
      </div>
    );
  }

  return (
    <AntModal
      width="fit-content"
      closable={false}
      mask
      centered
      okButtonProps={{ type: 'primary' }}
      {...rest}
      className={classNames('muse-modal', props.className)}
    >
      {getHeader()}
      {children}
    </AntModal>
  );
}
